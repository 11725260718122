import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Subscription } from 'rxjs';
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { FormDataJobDriverLocation } from "../../interface";
import { NzOptionSelectionChange } from "ng-zorro-antd/auto-complete";


@Component({
  selector: '[add-driver-location]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class AddDriverLocation extends BaseFormDialog1<FormDataJobDriverLocation> {

  @Input() jobId;
  public displayInfo: any = {};
  private subAddressSuggestion: Subscription;
  private timerAddressSuggestion;
  public listAddressAutoComplete: any = {};
  allStates = [
    {label: 'US States', items: MasterData.getStatesUS()},
    {label: 'Canada Provinces', items: MasterData.getCanadaProvinces()},
  ];

  @ViewChild('driverLocation', { static: false }) driverLocation: ElementRef<HTMLInputElement>;

  protected formGroupDeclaration: FormGroupDeclaration = {
    city: { label: "City", required: true },
    state: { label: "State", required: true },
  };

  get isCreateNew(): boolean {
    return !this.model;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  ngOnDestroy(): void {
    this.subAddressSuggestion?.unsubscribe();
    super.ngOnDestroy();
  }

  private getData() {
    let url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}`;
    this.api.GET(url).subscribe(
      (resp) => {
        let currentLocation = resp.data?.assignedDriver?.currentLocation;
        if (currentLocation?.city && currentLocation?.state) {
          this.displayInfo.lastLocation = `${currentLocation.city}, ${currentLocation.state}`
        }
      },
      (err) => {
      }
    );
  }

  public onBtnSave() {
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    if (!this.needUpdate) return;
    let data: FormDataJobDriverLocation = this.getFormData_JSON(true);
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/driver_location`, data).subscribe(
      resp => {
        let msg = 'Driver Location has been updated successfully.';
        this.showInfo(msg);
        this.updateSuccess(resp);
        if (this.closeOnSuccess) {
          this.closeDialog();
        }
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  private tryAddressSuggestion(elm: HTMLInputElement) {
    clearTimeout(this.timerAddressSuggestion);
    let userInput = elm.value;
    if (!userInput) {
      this.subAddressSuggestion?.unsubscribe();
      this.listAddressAutoComplete[elm.id] = [];
      return;
    }
    this.timerAddressSuggestion = setTimeout(() => this.doAddressSuggestion(elm, userInput), 100);
  }

  private doAddressSuggestion(elm: HTMLInputElement, userInput: string) {
    this.subAddressSuggestion?.unsubscribe();
    this.subAddressSuggestion = this.api.searchUsCities(userInput).subscribe(
      resp => {
        this.listAddressAutoComplete[elm.id] = resp.data.list_data.map(it => `${it.city}, ${it.stateCode}`);
      }, err => {
        Log.e('doAddressSuggestion failed. ', err);
      }
    );
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'city':
        return this.tryAddressSuggestion(event.target);
      default:
        return super.onInputChanged(event, key);
    }
  }

  onLocationSelected(event: NzOptionSelectionChange, location) {
    if (!event.isUserInput) {
      return;
    }
    this.setLocation(location);
  }

  getCityItem(item) {
    let arr = item?.trim()?.split(',') || [];
    if (arr.length == 2) {
      return arr[0]
    } else {
      return item;
    }
  }

  private setLocation(location: string) {
    let arr = location?.trim()?.split(',') || [];
    if (arr.length == 2) {
      this.setItemValue('city',arr[0].trim());
      this.setItemValue('state',arr[1].trim());
    }
  }

}
