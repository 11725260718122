import { Job } from "@wearewarp/types/data-model";
import { Const } from "@wearewarp/universal-libs";
import TaskEntity from "./TaskEntity";
import StopEntity from "./StopEntity";
import { getInjector } from "@services/index";
import { RouteService } from "../services/route.service";
import ShipmentEntity from "./ShipmentEntity";
import { Const as WarpConst } from "@wearewarp/universal-libs";

export default class RouteEntity {
  private data: Job
  private stops: StopEntity[] = [];
  private tasks: TaskEntity[] = []
  private ghostTasks: TaskEntity[] = []
  private shipments: ShipmentEntity[] = [];
  private routeService: RouteService;

  constructor(
  ) {
    const injector = getInjector();
    this.routeService = injector.get(RouteService);
  }

  public setRouteService(routeService) {
    if (routeService) {
      this.routeService = routeService;
    }
    return this;
  }

  public setData(data) {
    this.data = data;
    return this
  }

  public async init() {
    await this.initTasks();
    // await this.initStops();
    await this.initShipments();
    await this.generateStop();
    await this.generateTraffic();
    await this.refreshDisplay();
  }

  async refreshDisplay() {
    this.tasks.map(task => task.refreshDisplay())
  }

  // private async initStops() {
  //   if (!this.data.stops) {
  //     console.error(`Stops Data not found!`);
  //     return;
  //   }
  //   this.stops = await Promise.all(this.data.stops.map(async (stop, index) => await new StopEntity().setIndex(index).init(stop)))
  // }

  private async initTasks() {
    let tasks = this.data.taskIds.map(taskId => this.routeService.getTaskById(taskId)).filter(task => task);
    this.tasks = tasks.filter(task => task.getShipmentId());
    this.ghostTasks = tasks.filter(task => !task.getShipmentId());
  }

  private async initShipments() {
    const shipmentIds = this.data.shipments.map(it => it.id);
    this.shipments = shipmentIds.map(shipmentId => this.routeService.getShipmentById(shipmentId));
  }


  public getCode() {
    return this.data.code || ""
  }

  public getStatus() {
    return this.data.status || Const.JobStatus.created
  }

  public getId() {
    return this.data.id
  }

  public getData() {
    return this.data;
  }

  public getShipments() {
    return this.shipments
  }

  public updateShipments(shipments) {
    this.shipments = shipments;
    //update vào Map Shipment để phục vụ query
    this.shipments.map(shipment => this.routeService.addShipment(shipment));
    return this
  }

  public isAssignedCarrier() {
    return this.data.assignedCarrier ? true : false
  }
  public getCarrier() {
    return this.data.carrier
  }

  public getCarrierCost() {
    //need define more type
    return this.data?.assignedCarrier?.cost
  }

  public getDriverDownTime() {
    return this.data?.driverDownTime
  }
  public getDriver() {
    return this.data.driver
  }
  public getTrailerNumber() {
    return this.data.trailerNumber
  }

  public isAssignedDriver() {
    return this.data.assignedDriver ? true : false
  }

  public getBolFileId() {
    return this.data.bolFileId;
  }
  public getAssignedCarrier() {
    return this.data.assignedCarrier
  }
  public getClients() {
    return this.data.clients
  }

  public getType() {
    return this.data.type ?? WarpConst.JobType.normal;
  }

  public getGhostTask() {
    return this.ghostTasks;
  }

  public getTasks() {
    return this.tasks
  }

  public getTasksPickup() {
    const tasks = this.getTasks();
    return tasks.filter(it => it.getType() === WarpConst.TaskType.PICKUP);
  }

  public getStops() {
    let stops = [];
    let currentIndex = -1;
    for (let task of this.getTasks()) {
      if (task.getStopIndex() != currentIndex) {
        const stop = new StopEntity().init({
          type: task.getType(),
          info: task.getInfo(),
          status: task.getStatus()
        }).setIndex(task.getStopIndex()).addTask(task);
        stops.push(stop);
        currentIndex = task.getStopIndex()
      }
      else {
        const lastStop = stops[stops.length - 1];
        lastStop.addTask(task);
      }
    }
    return stops
  }

  public isValid() {
    const tasks = this.getTasks();
    for (let task of tasks) {
      if (!task.isValid()) return false;
    }

    return true
  }

  async updateTasks(tasks, { reGenerateStop } = { reGenerateStop: true }) {
    this.tasks = tasks;
    if (reGenerateStop) {
      await this.generateStop();
    }
    await this.generateTraffic();
    this.tasks.map(task => task.refreshDisplay())
  }

  addTask(task) {
    this.tasks.push(task);
    this.routeService.addTask(task)
    return this;
  }

  removeTaskByShipmentId(shipmentId) {
    this.tasks = this.tasks.filter(task => task.getShipmentId() != shipmentId);
    return this;
  }

  async generateStop() {
    const tasks = this.getTasks();
    let currentStop = 0;
    let prevTask = tasks[0];
    prevTask?.setStopIndex(currentStop);

    for (let i = 1; i < tasks.length; i++) {
      let currentTask = tasks[i];
      if (!currentTask.canSameStop(prevTask)) {
        currentStop++;
      }
      currentTask.setStopIndex(currentStop);
      prevTask = currentTask;
    }
  }

  async generateTraffic() {
    const tasks = this.getTasks();
    if (tasks.length < 2) return;
    let listFrom = tasks.slice(0, tasks.length - 1).map(task => ({ lat: task?.getLocation()?.latitude, lng: task?.getLocation()?.longitude }));
    let listTo = tasks.slice(1, tasks.length).map(task => ({ lat: task?.getLocation()?.latitude, lng: task?.getLocation()?.longitude }));
    const resp = await this.routeService.getTraffic({
      listFrom,
      listTo
    })

    const costs = resp?.costs || [];
    for (let i = 0; i < tasks.length; i++) {
      if (!costs[i]) {
        tasks[i].setCost({ distance: 0, time: 0 });
      } else {
        tasks[i].setCost(costs[i]);
      }
    }
  }

  public toJSON() {
    return this.data
  }
}
