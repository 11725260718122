import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-assign-pic",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class AssignPic extends BaseFormDialog1 {

  public users;
  public isError = false;
  public isLoading = false;
  public picId;
  private subAssignPic: Subscription;
  public pic;

  @Input() jobId: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getJobDetail();
    this.getDispatchers();
  }

  ngOnDestroy(): void {
    this.subAssignPic?.unsubscribe();
  }

  get canClickOK(): boolean {
    if (this.onProgress) return false;
    let currentPicId = this.pic?.id;
    return this.picId != currentPicId;
  }

  get labelOK(): string {
    return this.pic?.id ? 'Reassign' : 'Assign';
  }

  private getJobDetail() {
    let url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.pic = resp.data?.pic;
        this.picId = this.pic?.id;
      },
      (err) => { }
    );
  }

  private getDispatchers() {
    this.isError = false;
    this.isLoading = true;
    this.api.getListAdminUsers().subscribe(
      (resp) => {
        this.users = resp?.data?.list_data ?? [];
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
        this.isError = true;
      }
    );
  }

  private getPicUser(picUserId) {
    return this.users.find(it => it.id == picUserId);
  }

  public onBtnSelfIncharge() {
    this.picId = this.authUser.id;
  }

  public onBtnSave() {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    this.startProgress();
    let picUser = this.getPicUser(this.picId);
    if(!picUser) return;
    this.subAssignPic = this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/assign_dispatcher`, {
      picId: picUser.id
    }).subscribe(
      resp => {
        let msg = 'You have successfully assigned person in charge.';
        this.showInfo(msg);
        this.updateSuccess(resp);
        if (this.closeOnSuccess) {
          this.closeDialog();
        }
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }
  public onBtnUnassignDispatcher() {
    this.confirmYesNo('Do you really want to unassign PIC?', () => {
      this.doUnAssignDispatcher();
    });

  }
  private doUnAssignDispatcher(){
    if (!this.jobId) return Log.e("jobId is required");
    this.startProgress();
    let picUser = this.getPicUser(this.picId);
    if(!picUser) return;
    this.subAssignPic = this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/unassign_dispatcher`, null)
      .subscribe(resp => {
          let msg = 'You have successfully unassigned person in charge.';
          this.showInfo(msg);
          this.updateSuccess(resp);
          if (this.closeOnSuccess) {
            this.closeDialog();
          }
          this.stopProgress();
        }, err => {
          this.showErr(err);
          this.stopProgress();
        }
      );
  }
}
