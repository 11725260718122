<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="!isLoading">
  <div nz-row [nzGutter]="{ sm: 16 }" class="row-item">
    <div nz-col nzSm="12">
      <div class="label">Sales Rep</div>
      <div class="value">{{displayInfo?.saleRep || 'N/A'}}</div>
    </div>
    <div nz-col nzSm="12">
      <div class="label">Carrier Sales Rep</div>
      <div class="value">
        {{displayInfo?.carrierSalesRep || 'N/A'}}
        <button class="btn-update" nz-button nzType="link" nzSize="small"
          (click)="onBtnUpdateCarrierSalesRep()">Update</button>
      </div>
    </div>
  </div>
  <div nz-row [nzGutter]="{ sm: 16 }" class="row-item">
    <div nz-col nzSm="12">
      <div class="label">Client Sales Rep</div>
      <div class="value">{{displayInfo?.clientSalesRep || 'N/A'}}</div>
    </div>
  </div>
</div>