<div class="sidebar-map" *ngIf="!isLoading">
  <!-- <div class="map-image" (click)="openMap()">
    <dispatch-map
      *ngIf="!routeMapV2"
      [job]="job" 
      [listTasks]="tasks" 
      [shipments]="shipments"
      [mapId]="'minimum'"
    ></dispatch-map>
  </div> -->
  <div class="map-image">
    <!-- <img src="/assets/img/map-dispatch.svg" style="width: 100%;" /> -->
    <dispatch-route-map 
      *ngIf="routeMapV2 && isVisibleMiniMap"
      [driverId]="driverId"
      [data]="{job}"
      [shipments]="shipments"
    ></dispatch-route-map>
    <div class="empty-map" *ngIf="!isVisibleMiniMap" style="height: 200px;">

    </div>
  </div>
  <div class="btn-open-map"  (click)="openMap()">
    <img src="/assets/img/icon-open-map.svg" alt="">
  </div>
</div>
<div class="flex">
  <div class="flex1">
    <ng-container *ngTemplateOutlet="nextEta"></ng-container>
  </div>
  <div>
    <button nz-button nzSize="small" [nzLoading]="downloading" nzType="text" (click)="onDownloadGps()"><span nz-icon nzType="download"></span></button>
  </div>
</div>


<nz-modal [nzVisible]="isVisibleMap" 
  [nzMaskClosable]="false" nzWidth="80%" 
  nzTitle="Route ID: {{jobCode}}" (nzOnCancel)="handleCancel()" 
  nzWrapClassName="map-modal"
>
  <ng-container *nzModalContent>
    <!-- <dispatch-map *ngIf="!routeMapV2"
      [job]="job" 
      [listTasks]="tasks" 
      [shipments]="shipments"
      [mapId]="'maximum'" 
    ></dispatch-map> -->
    <div  *ngIf="routeMapV2" class="map-wrapper">
      <dispatch-route-map 
        [driverId]="driverId"
        [data]="{job}"
        [shipments]="shipments"
        [isSmallMap]="!routeMapV2"
        (onDestroy)="onBigMapDestroy()"
      ></dispatch-route-map>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>

<ng-template #nextEta>
  <div *ngIf="eta">
    <div style="padding: 6px;">
      <span class="right5">Next stop:</span>
      <span class="right5"><strong>{{ eta.miles }}</strong> mi</span>
      <span class="right5">/</span>
      <span><strong>{{ eta.hours }}</strong> h</span>
    </div>
    <div style="padding: 0 6px 6px 6px; font-size: 12px;">
      <span class="right5">Updated at:</span>
      <span><strong>{{eta.updatedAt}}</strong></span>
    </div>
  </div>
</ng-template>