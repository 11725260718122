import { WarpId } from '@wearewarp/universal-libs';
import { getInjector } from "@services/index";
import { LngLat, LngLatLike, Marker, MarkerOptions } from "mapbox-gl";
import { Const } from '@const/Const';
import { MapLocationType, ModelMapMarker } from '../../services/map.interface';
import { RouteService } from '../../services/route.service';
import { MasterData } from '@services/master.data';

export class MapMarker extends Marker {
  private onClickFn;
  private options: MarkerOptions;
  private extraData: ModelMapMarker = { data: [], location: [] };
  private routeService: RouteService;

  constructor(options?: MarkerOptions) {
    super({ scale: 0.5, ...options });
    const injector = getInjector();
    this.routeService = injector.get(RouteService);
    this.options = options || {};
  }
  //khai báo handle function cho event onclick
  onClick(handleClick) {
    this.onClickFn = handleClick;
    return this;
  }

  //override native function của mapbox để handle event onclick
  _onMapClick(e) {
    const targetElement = e.originalEvent.target;
    const element = (<any>this)._element;

    if (this.onClickFn && (targetElement === element || element.contains((targetElement)))) {
      this.onClickFn(this);
    }
  }

  setExtraData(data) {
    this.extraData = {
      ...this.extraData,
      ...data
    }
    return this;
  }

  getExtraData() {
    return this.extraData;
  }

  /**
   * add thêm các thông tin bổ sung vào mảng extraData
   * @param data 
   * @returns 
   */
  addExtraData(data) {
    this.extraData.data.push(data);
    return this;
  }

  /**
   * 
   * @param location [long, lat]
   * @returns 
   */
  setLngLat(location: LngLatLike) {
    this.setExtraData({
      location
    });
    return super.setLngLat(location);
  }

  /**
   * Lấy toạ độ, phục vụ vẽ map
   * @returns 
   */
  public getCoordinates(): LngLat {
    return this.getLngLat()
  }

  getKey() {
    return `${this.getExtraData().location.slice().reverse().join(", ")}-${this.getType()}`
  }

  /**
   * Lấy loại marker là Pickup/dropoff
   * @returns 
   */
  getType(): MapLocationType {
    return this.getExtraData()?.data?.[0]?.type
  }

  /**
   * Cập nhật style của marker
   * @param color: mã màu của marker 
   */
  setPaint({ color }) {
    const element = this.getElement();
    if (color) {
      element?.querySelector("path")?.setAttribute("fill", color)
    }
  }

  toggleSelect() {
    const element = this.getElement();
    const selected = element.getAttribute("selected") || "0"
    element.setAttribute("selected", selected == "0" ? "1" : "0");
  }

  selected() {
    const element = this.getElement();
    element.setAttribute("selected", "1");
  }
  unSelected() {
    const element = this.getElement();
    element.setAttribute("selected", "0");
  }
  /**
   * build popup content
   * @returns 
   */
  getPopupContent() {
    const extraData = this.getExtraData();
    const locationName = extraData?.data?.[0]?.locationName
    const addressText: any = MasterData.getAddressText(extraData?.data?.[0]?.addr);
    const shipmentIds = extraData.data.map(item => WarpId.showShipment(item.warpId));
    const locationType = extraData?.data?.[0]?.type;
    const pickupShipmentIds = extraData.data.filter(item => item.type == Const.TaskType.PICKUP).map(item => WarpId.showShipment(item.warpId));
    const dropoffShipmentIds = extraData.data.filter(item => item.type == Const.TaskType.DROPOFF).map(item => WarpId.showShipment(item.warpId));
    let desciprptionText = '';
    if (shipmentIds.length > 1) {
      desciprptionText = `${shipmentIds.length} shipments have the same location (${this.getKey()})`
    }
    else {
      desciprptionText = `${locationType} location (${this.getKey()})`;
    }

    return `<div class="shipment-popup-item">
           <div>
           ${desciprptionText}<br/>
           ${locationName ? `<b>${locationName}</b><br />` : ''}
           <b>Address: </b>${`${addressText ?? 'N/A'}`}</div>
           ${pickupShipmentIds.length ? `
           <hr/><b>PICKUP:</b><br />
           <span class="clickable shipment-popup-item">${pickupShipmentIds.join(", ")}</span>` : ''}
           ${dropoffShipmentIds.length ? `
           <hr/><b>DROPOFF:</b><br />
           <span class="clickable shipment-popup-item">${dropoffShipmentIds.join(", ")}</span>` : ''}
         </div>`;
  }
};
