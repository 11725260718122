import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { Subscription } from "rxjs";
import { DispatchService } from "../../dispatchService";
import RouteEntity from "../../entity/RouteEntity";
import { DialogService } from "@dialogs/dialog.service";
import { AddCarrierSaleRep } from "./add-carrier-sale-rep";

@Component({
  selector: '[dispatch-sales]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class DispatchSales extends BaseComponent {

  public isLoading = true;
  public displayInfo: any = {};
  protected route: RouteEntity;
  public saleRepId; // ULID
  public clientSalesRepId; // ULID

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected dispatchService: DispatchService
  ) {
    super(activatedRoute)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        this.resetData();
        let clients = this.route?.getClients() || [];
        for (let client of clients) {
          if (client?.saleUserId) this.saleRepId = client.saleUserId;
          if (client?.customerServiceUserId) this.clientSalesRepId = client.customerServiceUserId;
        }
        this.displayInfo.carrierSalesRep = this.getFullName(this.route?.getCarrierSalesRep());
        this.getData();
      })
    )
    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  getData() {
    let urls = [];
    if (this.saleRepId) urls.push(`${Const.APIURI_USERS}/${this.saleRepId}`);
    if (this.clientSalesRepId) urls.push(`${Const.APIURI_USERS}/${this.clientSalesRepId}`);

    if (!urls.length) return;
    //không sử dụng concurrentGET vì nếu có 1 user bị xoá thì sẽ ảnh hưởng đến toàn bộ các query khác.
    this.isLoading = true;
    Promise.allSettled(urls.map(url => this.api.GET(url).toPromise())).then(result => {
      let resp = result.filter(item => item.status == 'fulfilled').map((item: any) => item.value);
      for (let i=0; i<resp.length; i++) {
        if (this.saleRepId == resp[i].data?.id) {
          this.displayInfo.saleRep = this.getFullName(resp[i].data);
        }
        if (this.clientSalesRepId ==  resp[i].data?.id) {
          this.displayInfo.clientSalesRep = this.getFullName(resp[i].data);
        }
      }
      this.isLoading = false;
    })
  }

  private resetData() {
    this.displayInfo = {};
    this.saleRepId = null;
    this.clientSalesRepId = null;
  }

  onBtnUpdateCarrierSalesRep() {
    const jobId = this.route?.getId();
    const carrierSalesRepId = this.route?.getCarrierSalesRep()?.id;
    DialogService.openFormDialog1(AddCarrierSaleRep, {
      nzComponentParams: {
        jobId: jobId,
        carrierSalesRepId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding add-custom-tracking-form',
    });
  }

}
