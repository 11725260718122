import {Component, EventEmitter, Output} from "@angular/core";
import {RouteService} from "../../services/route.service";
import RouteEntity from "../../entities/RouteEntity";
import TaskEntity from "../../entities/TaskEntity";
import { Const } from '@const/Const';
import { BaseComponent } from "@abstract/BaseComponent";
import ShipmentEntity from "../../entities/ShipmentEntity";
import { DialogService } from "@dialogs/dialog.service";
import { ValidateMergeRoute } from "../validate-merge-route-modal";
@Component({
  selector: 'merge-route-shipment',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class MergeRouteShipment extends BaseComponent {
  public route: RouteEntity;
  public shipments: ShipmentEntity[]
  public routeId: string;
  public newListRoute: any[] = [];
  public newRouteOptions: any[] = [];

  public isLoading: boolean = true;
  @Output() listRouteForCancel: EventEmitter<RouteEntity[]> = new EventEmitter<RouteEntity[]>();

  constructor(
    private routeService: RouteService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscription.add(
      this.routeService.routeData$.subscribe(() => {
        this.route = this.routeService.getRoute();
        this.shipments = this.route?.getShipments();
      })
    )
    this.subscription.add(
      this.routeService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  async searchNewRoute($event){
    if (!$event) return;
    const result = await this.routeService.getListRouteForAddMore({
      condition: {}
    }, $event);
    // this.newRouteOptions = [...this.newListRoute, ...result.map(item => new RouteEntity().setData(item))];
    this.newRouteOptions = result.map(item => new RouteEntity().setData(item));
    if(this.newListRoute?.length > 0) {
      this.newRouteOptions = this.newRouteOptions.filter(route => !this.newListRoute.find(item => item.getId() == route.getId()));
    }
  }

  onRemoveShipment(shipment: ShipmentEntity) {
    this.shipments = this.shipments.filter(s => s.getId() != shipment.getId());
    this.route
      .updateShipments(this.shipments)
      .removeTaskByShipmentId(shipment.getId())
      .updateTasks(this.route.getTasks())
  }

  shouldShowBtnMerge() {
    return this.newListRoute?.length > 0;
  }

  onBtnMerge() {
    DialogService.openFormDialog1(ValidateMergeRoute, {
      nzClassName: 'modal modal-xl',
      nzComponentParams: {
        listRoute: this.newListRoute,
        _listRouteForMerge: (routes: RouteEntity[]) => {
          this.mergeRoute(routes);
        }
      }
    })
  }

  mergeRoute(routes: RouteEntity[]) {
    const listShipmentForMerge = routes.map(route => route.getShipments()).flat();
    this.shipments = [...listShipmentForMerge, ...this.shipments];
  
    this.route.updateShipments(this.shipments);
    listShipmentForMerge.map(shipment => {
        this.route
        .addTask(new TaskEntity().initByShipment(shipment, Const.TaskType.PICKUP))
        .addTask(new TaskEntity().initByShipment(shipment, Const.TaskType.DROPOFF))
    });
    this.route.updateTasks(this.route.getTasks());

    this.newListRoute = [];
    this.newRouteOptions = [];
    this.showSuccess(`Merge route <b>${routes.map(route => route.getCode()).join(', ')}</b> to route <b>${this.route.getCode()}</b>.`);
    // lấy list route cho việc cancel route 
    this.listRouteForCancel.emit(routes);
  }

  checkRouteSame(route: RouteEntity) {
    return this.route.getId() == route.getId();
  }
}
