import { Component, Input } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { BaseDrawer } from "@app/drawers/base-drawer";

@Component({
  selector: "[dispatch-order-issue-drawer]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DispatchOrderIssue extends BaseDrawer {

  @Input() orderId: string;

  data: any[] = [];

  constructor(protected activatedRoute: ActivatedRoute, protected dispatchService: DispatchService) {
    super();
  }

}
