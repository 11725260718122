import {Component, Input } from "@angular/core";
import { RouteService } from "../../services/route.service";
import RouteEntity from "../../entities/RouteEntity";
import { Const } from "@wearewarp/universal-libs";
import { BaseComponent } from "@abstract/BaseComponent";
import ShipmentEntity from "../../entities/ShipmentEntity";
import {FormDataUpdateTaskStatus} from '@wearewarp/ng-antd';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { Utils } from "@services/utils";
import { SortedTaskByShipmentId } from "@wearewarp/types/rest-api/admin";

@Component({
  selector: 'merge-route-shipment-screen',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class MergeRouteShipmentScreen extends BaseComponent {
  public _routeId: string;
  public route: RouteEntity;

  public isLoading: boolean = true;
  public shipments: ShipmentEntity[]
  public step = 0;
  public isSubmitting: boolean = false;
  private listRouteForCancel: RouteEntity[] = [];

  constructor(
    private routeService: RouteService
  ) {
    super()
  }

  @Input() onFinish: Function
  @Input() set routeId(routeId) {
    this._routeId = routeId;
    this.routeService.setRouteId(routeId).refresh()
  }
  get routeId() {
    return this._routeId
  }

  ngOnInit(): void {
    this.subscription.add(
      this.routeService.routeData$.subscribe(() => {
        this.route = this.routeService.getRoute();
      })
    )
    this.subscription.add(
      this.routeService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  onCancel() {
    if (this.onFinish) this.onFinish();
  }

  onNext() {
    this.step++;
  }

  public canSubmit() {
    return this.routeService.getRoute()?.isValid();
  }

  async onSubmit() {
    this.isSubmitting = true;
    await this._onSubmitCancelAllRoute();
    await this.addShipmentToRoute();
    if (this.onFinish) this.onFinish();
    this.isSubmitting = false;
    this.showSuccess('Edit sequence of route successfully!')
  }

  async _onSubmitCancelAllRoute(cancelShipments: boolean = false) {
    //cancel all route in listRouteForCancel
    const tasks = this.listRouteForCancel.map(route => route.getTasksPickup().map(it => it?.toJSON())).flat();
    const params: FormDataUpdateTaskStatus = {
      status: Const.TaskStatus.canceled,
      cancelShipments: cancelShipments,
    }
    await this.routeService.updateTaskStatus({
      tasks,
      data: params
    });
  }

  getDataListRouteForCancel(data: RouteEntity[]) {
    this.listRouteForCancel = data;
  }

  async addShipmentToRoute() {
    if (this.route.getType() != WarpConst.JobType.ghost) {
      // trường hợp add/remove shipment bình thường
      await this.routeService.createRoute({
        shipmentIds: Utils.uniqElementsArray(this.route.getTasks().map(task => task.getShipmentId())),
        sortedTasks: <SortedTaskByShipmentId[]>this.route.getTasks().map(task => ({
          shipmentId: task.getShipmentId(),
          type: <any>task.getType()
        }))
      });
    } else {
      // trường hợp add shipment vào ghost load
      await this.routeService.addShipmentsToManualLoad({
        shipmentIds: Utils.uniqElementsArray(this.route.getTasks().map(task => task.getShipmentId())),
      });
    }
  }
}
