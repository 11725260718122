<div class="flex-column" style="height: 100%;">
  <div class="flex-column" style="flex: 1">
    <div class="tasks">
      <div class="task-list">
        <div cdkDropListGroup>
          <div class="example-container">
            <div cdkDropList [cdkDropListData]="tasks" class="list" (cdkDropListDropped)="drop($event)">
              <div class="edit-route-sequence-task-item" *ngFor="let item of tasks; let index = index" cdkDrag
                [ngClass]="item.displayInfo.type">
                <div class="content" nz-tooltip="Drag to change the task order." nzTooltipPlacement="right">
                  <div class="title">
                    <nz-tag [nzColor]="item.displayInfo.type == 'DROPOFF'? 'green': 'purple'">
                      {{item.displayInfo.type}} {{item | taskWarpId}}
                    </nz-tag>
                    <nz-tag class="status {{item.displayInfo.status}}">
                      {{item.displayInfo.status}}
                    </nz-tag>

                    <route-stop-icon [task]="item" [tasks]="tasks"
                      [changeDetect]="[index, item.displayInfo.stopIndex]"></route-stop-icon>
                  </div>
                  <div class="description">
                    <div class="address">
                      <b *ngIf="item.displayInfo.locationName">{{item.displayInfo.locationName}}<br /></b>
                      {{item.displayInfo.addressText}}
                    </div>
                    <div class="invalidTask" *ngIf="!item.displayInfo.valid">
                      The position of the task is not valid. It could be because the DROPOFF task is already before the
                      PICKUP task. Please check again.
                    </div>
                  </div>
                </div>
                <div class="miles">
                  <nz-tag>
                    {{(item.displayInfo.cost?.distance / 1609.34).toFixed(2).toLocaleString()}} miles,
                    {{(item.displayInfo.cost?.time/ 3600).toFixed(2).toLocaleString()}} hours</nz-tag>
                </div>
                <div class="hide-dashed-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>