import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import RouteEntity from '../entities/RouteEntity';
import { Const } from '@const/Const';
import { ApiService } from '@services/api.service';
import { Task } from '@wearewarp/types/data-model';
import TaskEntity from '../entities/TaskEntity';
import ShipmentEntity from '../entities/ShipmentEntity';
import { FormDataUpdateTaskStatus } from '@wearewarp/ng-antd';
import { RequestAdminCreateRoute } from '@wearewarp/types/rest-api/admin';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private routeData = new BehaviorSubject({});
  private message: Subject<{ type: string, message: string }> = new Subject()
  public routeData$ = this.routeData.asObservable();
  public loading: Subject<boolean> = new BehaviorSubject(true);
  private routeId: string;
  private routeEntity: RouteEntity;
  private taskEntities: Map<string, TaskEntity> = new Map();
  private shipmentEntities: Map<string, ShipmentEntity> = new Map();

  constructor(private api: ApiService) {

  }

  changeData(data: string) {
    this.routeData.next(data)
  }

  public setRouteId(routeId) {
    this.routeId = routeId;
    return this;
  }

  public async refresh() {
    this.loading.next(true);
    await Promise.all([
      this.fetchRoute(),
      this.fetchTasks(),
      this.fetchShipments()
    ]).catch(e => {
      console.error(e);
    });
    await this.getRoute().init()
    this.routeData.next(this.routeEntity?.toJSON());
    this.loading.next(false)
  }

  public getRoute() {
    return this.routeEntity
  }

  public async fetchRoute() {
    if (!this.routeId) return;
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data;
      this.routeEntity = new RouteEntity().setRouteService(this);
      await this.routeEntity.setData(data);
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchTasks(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/tasks`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const taskEntity = new TaskEntity().setRouteService(this).init(item);
        this.taskEntities.set(taskEntity.getId(), taskEntity);
      }
    }
    catch (e) {
      console.error("fetTask ERR", e)
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchShipments(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/shipments`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const shipmentEntity = new ShipmentEntity().init(item);
        this.shipmentEntities.set(shipmentEntity.getId(), shipmentEntity);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async addShipment(shipment: ShipmentEntity) {
    this.shipmentEntities.set(shipment.getId(), shipment);
    return this;
  }

  public async updateDriver(driverId) {
    this.loading.next(true);
    //call api here

    this.loading.next(false);
    await this.refresh();
  }

  public async updateTaskStatus({ tasks, data }: { tasks: Task[], data: FormDataUpdateTaskStatus }) {
    try {
      let url = Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`);
      const resp = await this.api.POST(url, {
        action: 'updateStatus',
        data: tasks.map(task => ({
          id: task.id,
          data: data
        }))
      }).toPromise();
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async createRoute(params: RequestAdminCreateRoute) {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}`);
      const resp = await this.api.POST(url, params).toPromise();
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async addShipmentsToManualLoad(params) {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/add-shipments-to-manual-load`);
      const resp = await this.api.POST(url, params).toPromise();
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  async getTraffic({ listFrom, listTo }) {
    const resp = await this.api.POST(Const.APIURI_LTL_ROUTING(`routes/getTraffic`), {
      listFrom,
      listTo
    }).toPromise();
    return resp.data
  }

  public getTaskById(taskId: string) {
    return this.taskEntities.get(taskId);
  }
  public addTask(task: TaskEntity){
    this.taskEntities.set(task.getId(), task);
    return this;
  }

  public getShipmentById(shipmentId: string) {
    return this.shipmentEntities.get(shipmentId);
  }

  async getListShipmentForAddMore({ condition }) {
    let apiUrl = Const.APIURI_LTL_ROUTING('shipments');
    let params = { filter: JSON.stringify(condition) };
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += `${qs}&limit=-1`;
    const resp = await this.api.GET(apiUrl).toPromise();

    return resp.data.list_data || []
  }

  async getListRouteForAddMore({ condition }, search: string) {
    let apiUrl = Const.APIURI_JOBS + '/list/listForDispatchScreen';
    let params = { filter: JSON.stringify(condition) };
    params['search'] = search;
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += `${qs}`
    const resp = await this.api.GET(apiUrl).toPromise();
    const data = resp.data.list_data || [];
    return data;
  }
}
