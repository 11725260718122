<div class="adjust-shipment">
  <div class="add-new flex">
    <nz-select
    nzMode="tags"
    style="width: 350px"
    [(ngModel)]="warpIdsInput"
    (ngModelChange)="onChangeWarpIdsInput($event)"
    (keydown)="splitWarpIdsInput($event)"
    nzPlaceHolder="Enter WARP ID"
    >
    <nz-option nzLabel="Can be multiple WARP IDs" nzValue="" nzDisabled></nz-option>
  </nz-select>
  <div class="btn-add">
    <button [disabled]="!shouldShowBtnAddShipment" (click)="onBtnAddShipment()" nz-button nzType="primary">
      <i nz-icon nzType="plus"></i>
      Add
    </button>
  </div>
  </div>
  <div class="current-shipment">
    <nz-table #nzTable [nzData]="shipments" nzBordered="true" nzSize="small" [nzFrontPagination]="false"
      [nzShowPagination]="false" [nzLoading]="isLoading" [nzShowPagination]="false">
      <thead>
        <tr>
          <th>ID</th>
          <th>Pickup Address</th>
          <th>Dropoff Address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let shipment of nzTable.data">
          <td>{{ shipment.getWarpId() }}</td>
          <td>
            <b>{{shipment.getPickInfo().locationName}}</b><br />
            {{shipment?.getPickInfo() | addressText}}
          </td>
          <td>
            <b>{{shipment?.getDropInfo()?.locationName}}</b><br />
            {{shipment?.getDropInfo() | addressText}}
          </td>
          <td>
            <button nzDanger nz-button nzType="text" nz-popconfirm
              nzPopconfirmTitle="Are you sure to remove this shipment from the route?"
              (nzOnConfirm)="onRemoveShipment(shipment)">
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
