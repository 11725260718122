import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";

@Component({
  selector: "confirm-cancel-shipment-popup",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class ConfirmCancelShipmentPopup extends BaseComponent {
  @Input() onCancelShipment: () => void;
  @Input() onCancelRouteOnly: () => void;

  public cancellingShipment = false;
  public cancellingRouteOnly = false;


  async onClickCancelShipment() {
    this.cancellingShipment = true;
    await this.onCancelShipment();
    this.cancellingShipment = false;
  }
  async onClickCancelRouteOnly() {
    this.cancellingRouteOnly = true;
    await this.onCancelRouteOnly();
    this.cancellingRouteOnly = false;
  }
}