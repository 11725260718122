import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import RouteEntity from "../../entities/RouteEntity";
import StopEntity from "../../entities/StopEntity";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { DateUtil } from "@services/date-utils";
import ShipmentEntity from "../../entities/ShipmentEntity";
import { MasterData } from "@services/master.data";

@Component({
  selector: '[search-route-item]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})

export class SearchRouteItem extends BaseComponent {
  private _model: RouteEntity;
  private stops: StopEntity[] = [];
  private shipments: ShipmentEntity[] = [];
  displayInfo: any = {};
  @Input() get model() { return this._model }
  set model(value) {
    this._model = value;
    this.stops = this.getStops();
    this.shipments = this.getShipments();
    this.onModelChanged();
  }
  @Input() canRemove = false;

  constructor() {
    super();
  }

  getRouteId() {
    return this.model?.getCode() || "N/A";
  }
  
  getShipments() {
    return this.model?.getData()?.shipments?.map(shipment => new ShipmentEntity().init(shipment));
  }

  getStops() {
    return this.model?.getData()?.stops?.map(stop => new StopEntity().init(stop));
  }

  getFirstStop() {
    return this.stops[0];
  }

  getLastStop() {
    return this.stops[this.stops.length - 1];
  }

  getPickInfo() {
    return this.getFirstStop()?.getDeliveryInfo();
  }

  getDropInfo() {
    return this.getLastStop()?.getDeliveryInfo();
  }

  buildDeliveryInfo(deliveryInfo: DeliveryInfo) {
    const addressText = MasterData.getAddressText(deliveryInfo.addr);
    const timeWindow = deliveryInfo.windows?.[0];
    const locationName = deliveryInfo.locationName;
    const timezone = deliveryInfo?.addr?.metadata?.timeZoneStandard;
    return {
      addressText,
      timeWindow: timeWindow ? this.displayTimeWindow(timeWindow, timezone) : 'N/A',
      locationName
    }
  }

  displayTimeWindow(time, timezone) {
    return DateUtil.displayTimeWindow(time, {
      timezone: timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    })
  }

  countShipment() {
    return this.shipments.length || "N/A";
  }

  onModelChanged() {
    this.displayInfo = {
      routeId: this.getRouteId(),
      countShipment: this.countShipment(),
      pickInfo: this.buildDeliveryInfo(this.getPickInfo()),
      dropInfo: this.buildDeliveryInfo(this.getDropInfo()),
    }
  }
}