import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { Stop } from "@wearewarp/types/data-model";
import TaskEntity from "./TaskEntity";
import { getInjector } from "@services/injector";
import { RouteService } from "../services/route.service";
import { Const } from "@wearewarp/universal-libs";

export default class StopEntity {
  private data: Stop;
  private index: number;
  private tasks: TaskEntity[];
  private routeService: RouteService;

  constructor() {
    const injector = getInjector();
    this.routeService = injector.get(RouteService);
  }

  public setRouteService(routeService) {
    if (routeService) {
      this.routeService = routeService;
    }
    return this;
  }

  init(data) {
    console.log("init stop", data)
    this.data = data;
    // this.tasks = this.data.taskIds.map(taskId => this.routeService.getTaskById(taskId));
    return this;
  }


  addTask(task: TaskEntity) {
    if (!this.data.taskIds) this.data.taskIds = [];
    this.data.taskIds.push(task.getId());
    return this;
  }

  setIndex(index) {
    this.index = index;
    return this;
  }

  getIndex() {
    return this.index
  }
  getType() {
    return this.data.type
  }

  getId() {
    return this.data.id
  }

  getStatus() {
    return this.data.status
  }

  getLocationName() {
    return this.data.info.locationName
  }
  getAddressText() {
    return MasterData.getAddressText(this.data.info.addr)
  }
  getAddress() {
    return this.data.info.addr
  }
  getDeliveryInfo() {
    return this.data.info
  }

  getAppointment() {
    const appointment = this.data.info.appointmentInfo;
    if (appointment?.from) return appointment

    const timeWindow = this.data.info?.windows?.[0]
    if (timeWindow.from) return timeWindow;
  }
  isAppointmentSchduled() {
    return this.data.info?.appointmentInfo?.from ? true : false;
  }

  getAppointmentIssue() {
    if (this.data.info?.requiresAppointment && !this.isAppointmentSchduled()) return "Requires Appointment";
    if (this.data?.metadata?.conflictTime) return "Conflict time between shipments"
  }

  isMixedStatus() {
    return this.data?.metadata?.mixedStatus
  }
  getTimezone() {
    return this.data?.info?.addr?.metadata?.timeZoneStandard
  }
  getFirstTask() {
    return this.getTasks()?.find(task => ![Const.TaskStatus.canceled, Const.TaskStatus.failed, Const.TaskStatus.pickupFailed].includes(<any>task.getStatus()));

  }
  getArrivedTime() {
    return this.getFirstTask().getArrivedTime()
  }
  getDepartedTime() {
    return this.getFirstTask().getDepartedTime()
  }
  getETA() {
    return this.getFirstTask().getETA()
  }

  getTasks() {
    return this.data.taskIds.map(taskId => this.routeService.getTaskById(taskId))
  }
  getShipments() {
    return this.getTasks().map(task => task.getShipment()).filter(x => x);
  }

  getLastTask() {
    const tasks = this.getTasks();
    return tasks[tasks?.length - 1]
  }
  getCost() {
    return this.getLastTask()?.getCost()
  }

  isValid() {
    const tasks = this.getTasks();
    for (let task of tasks) {
      if (!task.isValid()) return false;
    }
    return true;
  }

}
