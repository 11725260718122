import { Component } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { Input } from "@angular/core";
import { NzFormatEmitEvent } from "ng-zorro-antd/tree";

@Component({
  selector: 'history-detail',
  templateUrl: './index.html',
  styleUrls: ['../../../../../dialogs/dialogs.scss', './index.scss']
})

export class HistoryDetail extends BaseDialog {
  @Input() data: any;

  nzEvent(event: NzFormatEmitEvent): void {

  }
}