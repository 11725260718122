import { Component, Input } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { BaseDrawer } from "@app/drawers/base-drawer";
import { User } from "@wearewarp/types/data-model";
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { BaseDialog } from "@dialogs/base-dlg";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: "[dispatch-send-mail-csr]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DispatchSendMailToCSR extends BaseComponent {

  @Input() recipients: string[];
  @Input() orderId: string;
  @Input() subject: string;
  @Input() content: string;

  sending: boolean = false;

  constructor(protected activatedRoute: ActivatedRoute, protected dispatchService: DispatchService) {
    super();
  }

  async sendEmail() {
    this.sending = true;
    this.api.POST(`${Const.APIV2(Const.APIURI_ORDERS)}/send-issue`, {
      content: this.content,
      subject: this.subject,
      receivers: this.recipients,
      orderId: this.orderId
    }).toPromise();

    const params = {
      content: `Email sent to ${this.recipients.join(", ")}:\n\n${this.content}`,
      subjectId: this.orderId,
      subjectType: WarpConst.ConversationSubjectType.orderIssue,
      type: WarpConst.ConversationType.note
    };
    const formData = new FormData();
    formData.append('params', JSON.stringify(params));

    await this.api.postFormData(`${Const.APIURI_CONVERSATIONS}`, formData).toPromise();
    this.showSuccess('Email has been sent successfully!');
    this.sending = false;
    this.modalService.closeAll()
  }

}
