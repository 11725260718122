import { OnInit, Input, Component } from "@angular/core";

@Component({
  selector: 'eta-icon',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class ETAIcon implements OnInit {

  @Input() fontSize = 14;
  ngOnInit(): void {
    
  }

}