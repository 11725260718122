import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { CarrierCostIssueConfirm } from './component';
import { ModuleNotes } from '@app/admin/components/notes/module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModuleNotes,
    NzCheckboxModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzAlertModule,
  ],
  declarations: [
    CarrierCostIssueConfirm
  ],
  exports: [
    CarrierCostIssueConfirm
  ]
})
export class ModuleCarrierCostIssueConfirm {}