<div class="right10 left10 top10" *ngIf="!isLoading">
  <nz-tabset>
    <nz-tab nzTitle="Notes">
      <note-list [jobId]="jobId"></note-list>
    </nz-tab>
    <nz-tab nzTitle="History">
      <history-list [objectId]="jobId" entity="jobs" [isCustomTimeline]="true"></history-list>
    </nz-tab>
    <nz-tab [nzTitle]="titleTemplate" (nzClick)="startIntervalDriverMessage()" (nzDeselect)="stopIntervalDriverMessage()">
      <ng-template #titleTemplate>
        <span *ngIf="showEvents" nz-icon nzType="comment" nzTheme="outline"></span>
        <span *ngIf="!showEvents">
          <ng-container *ngIf="!driverMessageNumber">Driver Messages</ng-container>
          <ng-container *ngIf="driverMessageNumber">
            <span>Driver Messages
              <nz-badge [nzCount]="driverMessageNumber" [nzOverflowCount]="9"></nz-badge>
            </span>
          </ng-container>
        </span>
      </ng-template>
      <driver-message #driverMessage [jobId]="jobId" ></driver-message>
    </nz-tab>
    <nz-tab *ngIf="showEvents" [nzTitle]="titleTemplate">
      <ng-template #titleTemplate>
        <span nz-icon nzType="compass" nzTheme="outline"></span>
      </ng-template>
      <events-container type="JOB" [id]="jobId"></events-container>
    </nz-tab>
</nz-tabset>
</div>