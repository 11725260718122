<div class="revfenue-info">
    <div class="component-body">
        <nz-table #revenueTable [nzData]="data" nzSize="small" [nzShowPagination]="false" [nzLoading]="loading">
            <thead>
                <tr>
                    <th nzWidth="120px">Shipment</th>
                    <th nzWidth="120px">Shipment Revenue</th>
                    <th nzWidth="120px">Order Revenue</th>
                    <th nzWidth="150px">CSR</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of revenueTable.data">
                    <td>
                        <a [routerLink]="[routeAdminShipmentList, data?.order?.id]" target="_blank">
                            <b>{{data?.order?.warpId}}</b>
                            <br>
                            <span class="shipmentID">({{data.warpId}})</span>
                        </a>
                    </td>
                    <td nzAlign="right">{{data.customerRate}}</td>
                    <td nzAlign="right">{{data.order?.customerRate}}</td>
                    <td>{{data.clientSalesRep}}</td>
                    <td>
                        <div class="actions">
                            <div class="btn-issue" (click)="onOpenSendMail(data)">
                                <span nz-icon nzType="mail" nzTheme="outline"></span>&nbsp;Email to Sales
                            </div>
                            <div class="btn-issue" (click)="onOpenIssue(data.order)">
                                <span nz-icon nzType="issues-close" nzTheme="outline"></span>&nbsp;Order Issue
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>

    </div>
</div>