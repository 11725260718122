<div class="edit-sequence">
  <div class="sidebar">
    <div class="text-box">
      <b>Edit Sequence Of Route.</b>
      <br />
      <nz-radio-group [(ngModel)]="mode">
        <label nz-radio-button nzValue="tasks">Tasks Mode</label>
        <label nz-radio-button nzValue="stops">Stops Mode</label>
      </nz-radio-group>
    </div>
    <div class="tasks" *ngIf="mode == 'tasks'">
      <route-task-list [(tasks)]="tasks" (tasksChanged)="tasksChanged()"></route-task-list>
    </div>
    <div class="stops" *ngIf="mode == 'stops'">
      <route-stop-list [(stops)]="stops" (stopsChanged)="stopsChanged()"></route-stop-list>
    </div>
    <div class="total">
      <div class="distance">
        <b>Total distance:</b> {{cost.distance}}
      </div>
      <div class="time">
        <b>Total time:</b> {{cost.time}}
      </div>
    </div>
  </div>
  <div class="map">
    <route-map></route-map>
  </div>

</div>