import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DispatchList } from './list';
import { DispatchDetailScreen } from './detail-screen';
import {DispatchDetailEmpty} from '@app/admin/dispatch/detail-empty';


const dispatchRoutes: Routes = [
  {
    path: '', component: DispatchList, data: { title: 'Dispatch - WARP Admin' }, children: [
      { path: 'empty', component: DispatchDetailEmpty, data: { title: 'Dispatch - WARP Admin' } },
      { path: ':id', component: DispatchDetailScreen, data: { title: 'Dispatch - WARP Admin' } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(dispatchRoutes)],
  exports: [RouterModule]
})
export class DispatchRoutingModule { }
