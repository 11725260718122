<form class="form-detail" nz-form>
  <div class="flex">
    <div class="f20 bottom20 flex1">Trailer & Truck Number</div>
  </div>
  <div class="flex top20 ">
    <div>Trailer Number</div>
  </div>
  <input
    nz-input
    maxlength="18"
    class="add-custom-tracking-content"
    placeholder="Enter trailer number here..."
    [(ngModel)]="trailerNumber"
    [ngModelOptions]="{standalone: true}"
    (ngModelChange)="onTrailerNumberChange()"
  />
  <div class="flex top20 ">
    <div >Truck Number</div>
  </div>
  <input
  nz-input
  maxlength="18"
  class="add-custom-tracking-content"
  placeholder="Enter truck number here..."
  [(ngModel)]="truckNumber"
  [ngModelOptions]="{standalone: true}"
  (ngModelChange)="onTruckNumberChange()"
/>
</form>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="true"
  [canClickOK]="!isDisabledSaveBtn()"
  labelOk="Save"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
