import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import ShipmentEntity from "../../entities/ShipmentEntity";
import RouteEntity from "../../entities/RouteEntity";
import { RouteService } from "../../services/route.service";
import { WarpId } from '@wearewarp/universal-libs';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { Utils } from "@services/utils";

@Component({
  selector: 'validate-shipment-modal',
  templateUrl: './index.html',
  styleUrls: ['./index.less']
})
export class ValidateShipmentModal extends BaseFormDialog1 {
  @Input() listWarpIds: any[] = [];
  @Input() listShipemnts: ShipmentEntity[] = [];
  @Input() routeEntity: RouteEntity;
  newShipmentOptions: any[] = [];
  listShipmentForTable: any[] = [];
  countShipments = {
    valid: 0,
    invalid: 0
  };
  constructor(
    private routeService: RouteService
    ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.removeBlankItemInListWarpIds();
    this.searchListShipment().then(() => {
      this.newArrTableShipment();
      this.countShipmentValidAndInvalid();
    });
  }

  removeBlankItemInListWarpIds() {
    this.listWarpIds = this.listWarpIds.filter(item => item !== '');
  }

  async searchListShipment() {
    if (!this.listWarpIds) return;
    const result = await this.routeService.getListShipmentForAddMore({
      condition: {
        warpIds: this.listWarpIds
      }
    });
    if (!result.length || result.length == 0) return;
    this.newShipmentOptions = result.map(item => new ShipmentEntity().init(item));
  }

  validateShipment(shipment: ShipmentEntity) {
    const isExist = this.listShipemnts.find(s => s.getId() == shipment.getId());
    if (isExist) {
      return `Shipment is already in the route.`;
    }
    if (shipment.getLastJobId()) {
      return `Shipment is already in the another route: `;
    }
    if (this.routeEntity && this.routeEntity.getType() == WarpConst.JobType.ghost) {
      let ghostTasks = this.routeEntity.getGhostTask();
      let taskPickZipcodes = ghostTasks.filter(it => it.getType() == WarpConst.TaskType.PICKUP).map(it => it.getAddress()?.zipcode).filter(x => x);
      let taskDropZipcodes = ghostTasks.filter(it => it.getType() == WarpConst.TaskType.DROPOFF).map(it => it.getAddress()?.zipcode).filter(x => x);
      taskPickZipcodes = Utils.uniqElementsArray(taskPickZipcodes);
      taskDropZipcodes = Utils.uniqElementsArray(taskDropZipcodes);
      let pickZipcode = shipment.getPickInfo()?.addr?.zipcode;
      let dropZipcode = shipment.getDropInfo()?.addr?.zipcode;
      if (!taskPickZipcodes.includes(pickZipcode)) {
        return `The pickup zipcode tasks ${taskPickZipcodes} not include pickup shipment zipcode ${pickZipcode}`;
      }
      if (!taskDropZipcodes.includes(dropZipcode)) {
        return `The dropoff zipcode tasks ${taskDropZipcodes} not include dropoff shipment zipcode ${dropZipcode}`;
      }
    }
  }

  newArrTableShipment() {
    this.listShipmentForTable = this.listWarpIds.map(item => {
      const shipment: ShipmentEntity = this.newShipmentOptions.find(s => s.getWarpId() == item);
      if(!shipment) {
        return {
          warpId: WarpId.showShipment(item),
          validate: false,
          reason: "Shipment not found OR Using Cross Dock."
        }
      }
      const reason = this.validateShipment(shipment);
      if(reason) {
        return {
          warpId: WarpId.showShipment(item),
          validate: false,
          reason,
          shipmentId: shipment.getId(),
          routeId: shipment.getLastJobId(),
          pickInfo: shipment.getPickInfo(),
          dropInfo: shipment.getDropInfo()
        }
      }
      return {
        warpId: WarpId.showShipment(item),
        validate: true,
        reason: "",
        pickInfo: shipment.getPickInfo(),
        dropInfo: shipment.getDropInfo(),
        onHold: shipment.getTags() && shipment.getTags().indexOf('HOLD') >= 0
      }
    });
  }

  shouldShowAddShipments() {
    let shipmentError = this.listShipmentForTable.find(item => item.validate == false);
    if(!shipmentError) return true;
    return false;
  }

  public listShipmentSelected: (shipments: ShipmentEntity[]) => void = () => {};

  onBtnAddShipment() {
    this.listShipmentSelected(this.newShipmentOptions);
    this.closeDialog();
  }

  countShipmentSelected() {
    return this.listWarpIds?.length;
  }

  countShipmentValidAndInvalid() {
    if(!this.listShipmentForTable) return;
    const result = {valid: 0, invalid: 0};
    this.listShipmentForTable.forEach(item => {
      if(item.validate) result.valid++;
      else result.invalid++;
    });
    this.countShipments = result;
  }

}
