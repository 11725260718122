import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import RouteEntity from "../../entity/RouteEntity";
import StopEntity from "../../entity/StopEntity";
import { Const } from "@const/Const";
import { DispatchLiveTracking } from "../../dispatch-live-tracking";
import { UIHelper } from "@services/UIHelper";

@Component({
  selector: 'dispatch-route-detail',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispatchRouteDetail {
  public isLoading = true;
  public displayInfo: any = {};
  protected subscription: Subscription = new Subscription();
  protected route: RouteEntity;
  public stopNeedUpdateETA: StopEntity;

  constructor(
    public activatedRoute: ActivatedRoute,
    private dispatchService: DispatchService,
    private dispatchLiveTracking: DispatchLiveTracking,
  ) {
  }

  ngOnInit(): void {
    const subs = [
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        if (!this.route) return;
        this.buildDisplayInfo();
        this.getStopNeedUpdateETA();
        this.trackTaskStatusChanged();
      }),
      this.dispatchService.loading.subscribe(value => {
        if (this.isLoading != value) {
          this.isLoading = value;
        }
      }),
    ];
    subs.map(it => this.subscription.add(it));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.taskStatusSubscription?.unsubscribe();
  }

  private get currentJobId() { return this.dispatchService.getRouteId() }
  private taskStatusSubscription: Subscription;

  private trackTaskStatusChanged() {
    this.taskStatusSubscription?.unsubscribe();
    this.taskStatusSubscription = new Subscription();
    const taskIds = this.route.getTaskIds();
    taskIds.map(taskId => {
      this.taskStatusSubscription.add(this.dispatchLiveTracking.trackTaskStatusChange(taskId, this.currentJobId, data => {
        if (data.job.id == this.currentJobId) {
          const task = this.dispatchService.getTaskById(taskId);
          let message = `<b>${data.authUser.name}</b> has just updated ${task.getType()} status to <b>${data.newStatus}</b>`;
          if (data.authUser.collection == 'drivers') {
            message = 'Driver ' + message;
          }
          UIHelper.showInfo(message);
          this.refresh();
        }
      }));
    })
  }

  private refresh() {
    this.dispatchService.refresh();
  }

  private async buildDisplayInfo() {
    if (!this.route) return;
    const stops = this.route.getStops();
    const issues = this.route.getIssues();

    this.displayInfo = {
      numberOfStops: stops.length,
      stops: stops,
      issues
    }
  }

  //optimize render
  public trackBy(index, stop){
    return stop.getId()
  }

  private async getStopNeedUpdateETA() {
    if (!this.route) return;
    const stops = this.route.getStops();
    const stopNeedUpdateETA = stops?.filter((stop) =>
    stop?.getTasks()?.find((task) =>
        [Const.TaskStatus.arrived, Const.TaskStatus.created, Const.TaskStatus.enroute].includes(<any>task.getStatus()) && !task.getETA()
      )
    );
    this.stopNeedUpdateETA = stopNeedUpdateETA.length ? stopNeedUpdateETA[0] : null;
  }
}
