<div class="flex bottom5 title">
  <div class="flex flex1">
    <a class="warp-id inherit" target="_blank">{{displayInfo.warpId}}</a>
    <ng-container *ngIf="displayInfo.client">
      <div class="left5">/</div>
      <a class="inherit left5" target="_blank">{{displayInfo.client?.name}}</a>
    </ng-container>
  </div>
</div>

<div class="flex">
  <div class="icon-direction noselect">
    <div class="dot">⬤</div>
    <div class="line"></div>
    <div class="dot triangle">▼</div>
  </div>
  <div class="flex1">
    <div class="pick-addr" nz-popover [nzPopoverContent]="displayInfo?.pickInfo?.addressText"
      nzPopoverPlacement="right">
      {{displayInfo?.pickInfo?.locationName}}</div>
    <div class="pick-time bottom5" [ngClass]="{'error': displayInfo?.pickInfo?.isWindowError}">{{displayInfo?.pickInfo?.timeWindow}}
    </div>
    <div class="pick-addr" nz-popover [nzPopoverContent]="displayInfo?.dropInfo?.addressText"
      nzPopoverPlacement="right">
      {{displayInfo?.dropInfo?.locationName}}</div>
    <div class="pick-time" [ngClass]="{'error': displayInfo?.dropInfo?.isWindowError}">{{displayInfo?.dropInfo?.timeWindow}}</div>

    <div *ngIf="displayInfo?.routeId" class="error">This shipment has been routed on a different route.
      RouteID:
      <a target="_blank" style="font-size: 13px;" [routerLink]="[routeAdminDispatchList, displayInfo?.routeId]"
        nz-tooltip nzTooltipTitle="Go to dispatch">
        <code>{{displayInfo?.routeId}}</code>
      </a>
    </div>
  </div>
</div>