import { Pipe, PipeTransform } from '@angular/core';
import { MasterData } from '@services/master.data';
import { DeliveryInfo } from '@wearewarp/types/data-model';
@Pipe({
  name: 'addressText'
})
export class AddressTextPipe implements PipeTransform {
  transform(deliveryInfo: DeliveryInfo, args?: any): any {
    return MasterData.getAddressText(deliveryInfo.addr)
  }
}