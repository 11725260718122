import { Injectable } from "@angular/core";
import { MasterData } from "@services/master.data";
import { Const } from "@const/Const";
import mapboxgl, { MapboxOptions, LngLatLike, Map as Mapbox, Marker, Popup, LngLatBounds, MarkerOptions } from 'mapbox-gl';
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { MapLocationType, ModelMapLineOptions, ModelMapMarker, ModelMapMarkerOption } from "./map.interface";
import { MapMarker, MapPopup, MapDraw, MapLine, MapBounds } from "../entities/map";
import StopEntity from "@app/admin/dispatch/entity/StopEntity";

interface ModelGroupStopByLocation {
  location: [number, number],
  stops: StopEntity[]
}

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private map: Mapbox;
  private drawControl: MapDraw;

  private markers: Map<string, MapMarker> = new Map();
  private lines: Map<string, MapLine> = new Map();
  private popup: MapPopup = new MapPopup({ offset: [0, -20] });
  public mapReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public mapDrawEvent: ReplaySubject<any> = new ReplaySubject(1);

  public createMap(options) {
    this.mapReady.next(false);
    mapboxgl.accessToken = MasterData.mapboxToken;
    this.map = new Mapbox({
      container: options.container,
      style: options.style ?? Const.MAPBOX_STYLE_LIGHT_V10,
      center: options.center ?? Const.MAP_LOCATION_LA,
      zoom: options.zoom ?? 9,
      pitch: 45
    });
    this.map.on('load', () => {
      this.popup.setMap(this.map);
      this.drawControl = new MapDraw().addTo(this.map)
        .onEvent('area_updated', this.handleDrawUpdated.bind(this))
        .onEvent('modechange', this.handleDrawModeChange.bind(this));
      this.map.resize();  // Chỗ này cần gọi resize để update width/height cho map. Nếu khai báo width/height của map cố định thì không cần.
      this.mapReady.next(true);
    })
  }
  public resize() {
    if (!this.mapReady.getValue()) return;
    this.map.resize()
  }

  public isReady(): boolean {
    return this.mapReady.getValue()
  }
  public waitMapReady(callbackFn) {
    const sub = this.mapReady.subscribe(isReady => {
      if (!isReady) return;
      callbackFn();
      setTimeout(() => sub.unsubscribe(), 1);
    })

  }

  public reset() {
    this.removeMarkers();
    this.removeLines();
    this.popup.remove();
    this.stopDraw();
  }

  public removeMarkers() {
    this.markers.forEach(maker => maker.remove());
    this.markers.clear();
  }
  public removeLines() {
    this.lines.forEach(line => line.remove());
    this.lines.clear();

  }

  /**
   * Marker
   * @param location: long,lat
   * @param data: {shipment_id: number, type: MapLocationType }
   */
  public addMarker(options: ModelMapMarkerOption) {
    if (!this.map) {
      console.error(`The map is not ready!`);
      return;
    }
    const { location, data } = options;
    const markerKey = `${this.textLatLng(location)}`;
    let marker: MapMarker;
    if (this.markers.has(markerKey)) {
      marker = this.markers.get(markerKey);
      marker.addExtraData(data)
    }
    else {
      marker = new MapMarker()
        .setExtraData({
          data: [data]
        })
        .setLngLat(<LngLatLike>location)
        .addTo(this.map)
        .onClick(this.handleMarkerClick.bind(this))
      this.markers.set(markerKey, marker);
    }
    return marker
  }

  public getMarker(markerKey) {
    return this.markers.get(markerKey)
  }

  public removeMarkerData({ filterFn }) {
    const markerKeys = Array.from(this.markers.keys());
    for (let markerKey of markerKeys) {
      const marker = this.markers.get(markerKey);
      let data = marker.getExtraData()?.data || [];
      data = data.filter((item) => !filterFn(item)); //xoá các data thoả mãn filterFn
      if (data.length == 0) {
        this.markers.delete(markerKey);
        return this;
      }
      marker.setExtraData({ data: data });
    }
  }

  private handleMarkerClick(marker: MapMarker) {
    this.popup.show({
      location: marker.getLngLat(),
      content: marker.getPopupContent()
    })
  }

  /**
   * Line
   * @param options 
   */
  public addLine(options: ModelMapLineOptions) {
    const mapLine = new MapLine(options.id).setCoordinates(options.coordinates).addTo(this.map);
    this.lines.set(options.id, mapLine);
    return mapLine
  }

  /**
   * Bounds
   */
  public fitBounds() {
    const mapBounds = new MapBounds().addTo(this.map);
    this.markers.forEach(marker => mapBounds.addCoordinates(marker.getCoordinates()));
    this.lines.forEach(line => mapBounds.addCoordinates(line.getCoordinates()));
    mapBounds.fitBounds();
    return this;
  }

  /**
   * DRAW Control
   */
  public startDraw() {
    this.drawControl?.start();
    return this;
  }

  public stopDraw() {
    this.drawControl?.stop();
    return this;
  }

  public isDrawing() {
    this.drawControl?.isDrawing();
  }

  private handleDrawUpdated() {
    this.mapDrawEvent.next({
      event: 'area_updated',
    })
  }
  private handleDrawModeChange() {
    this.mapDrawEvent.next({
      event: 'modechange',
    })
  }

  public getMarkersInSelectedArea({ type }: { type: MapLocationType }) {
    const markers = Array.from(this.markers.values());
    const selected = markers.filter(marker => {
      // if (marker.getType() !== type) return false;

      const location = marker.getLngLat().toArray();
      return this.drawControl.isPointInSelectedArea(location)
    })
    return selected
  }

  /**
   * 
   * @param location 
   * @returns 
   */
  private textLatLng(location) {
    //vì mảng location là long,lat, nên phải reverse trước khi join mới đúng key
    return location.slice().reverse().join(", ")
  }

  public getMakerColor(type) {
    const MARKER_COLOR_STOPS = {
      'PICKUP': '#7c4aed', // purple
      'DROPOFF': '#73df54' // green
    }
    return MARKER_COLOR_STOPS[type] || '#7c4aed'
  }
}