<div>
  <div style="font-size: 15px; padding: 5px 0;">Seach Jobs By Note</div>
  <div style="flex: 1; max-width: 250px;">
    <search-box #searchBox class="search-box" [placeHolder]="'Search'" [searchKeyword]="searchNoteKeyWord"
      (doSearch)="doSearchByNote($event)">
    </search-box>
  </div>
  
  <div class="summary-container top10">
    <span style="color: red;"><i>Total: {{ totalCount }}</i></span>
  </div>

  <div class="top10">
    <cdk-virtual-scroll-viewport *ngIf="!isSearching" itemSize="64" minBufferPx="256" maxBufferPx="256" class="cdk-virtual-scroll-element">
      <table class="ant-table table">
        <thead class="ant-table-thead">
          <tr>
            <th class="ant-table-cell" style="width: 130px;">Route ID</th>
            <th class="ant-table-cell">Note</th>
          </tr>
        </thead>
        <tbody class="ant-table-tbody">
          <tr class="ant-table-row" *cdkVirtualFor="let item of listConversations">
            <td class="ant-table-cell" style="min-width: 130px;">
              <a [routerLink]="[routeAdminDispatchList, item?.job?.id]" target="_blank" nz-tooltip nzTooltipTitle="Go to Detail">
                {{ item?.job?.code }}
              </a>
            </td>
            <td class="ant-table-cell">{{ item?.content }}</td>
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
  
  <div class="top10" form-footer [onProgress]="isSearching"
    [nzIconCancel]="null" [nzIconOK]="null" labelOK="Done"
    [canClickOK]="!isSearching" [canClickCancel]="!isSearching"
    (onOK)="closeDialog()" (onCancel)="closeDialog()"></div>
</div>
