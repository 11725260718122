<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading" style="display: flex;justify-content: space-between">
  <div class="f20 bottom20">Select person in charge</div>
  <button *ngIf="pic" nz-button nzDanger (click)="onBtnUnassignDispatcher()">Unassign PIC</button>
</div>

<a (click)="onBtnSelfIncharge()">I'm in charge</a>

<nz-form-item class="top20">
  <nz-form-control>
    <nz-select nzBackdrop="true" style="width: 100%;" [nzLoading]="isLoading" nzShowSearch
      nzPlaceHolder="Please select PiC" [(ngModel)]="picId">
      <nz-option *ngFor="let user of users" [nzLabel]="getFullName(user)" [nzValue]="user.id">
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>


<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="canClickOK" [canClickCancel]="!onProgress" [labelOK]="labelOK" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"></div>
