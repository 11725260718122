import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import ShipmentEntity from "../../entities/ShipmentEntity";
import { WarpId } from "@wearewarp/universal-libs";

@Component({
  selector: '[search-shipment-item]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class SearchShipmentItem extends BaseComponent {
  private _model: ShipmentEntity;
  @Input() get model() { return this._model }
  set model(value) {
    this._model = value;
    this.onModelChanged();
  }
  @Input() canRemove = false;
  @Output() removeMe = new EventEmitter<any>();

  public displayInfo: any = {};

  constructor() {
    super();
  }


  getClient() {
    return this.model.getClient()
  }

  getRouteId() {
    return this.model?.getLastJobId()
  }

  getPalletDesc() {
    return ""
  }

  getPickInfo() {
    const pickInfo = this.model.getPickInfo();
    return this.buildDeliveryInfo(pickInfo);
  }
  getDropInfo() {
    const dropInfo = this.model.getDropInfo();
    return this.buildDeliveryInfo(dropInfo);
  }

  buildDeliveryInfo(deliveryInfo) {
    const addressText = MasterData.getAddressText(deliveryInfo.addr);
    const timeWindow = deliveryInfo.appointmentInfo?.from ? deliveryInfo.appointmentInfo : deliveryInfo.windows?.[0]
    const locationName = deliveryInfo.locationName
    const timezone = deliveryInfo?.addr?.metadata?.timeZoneStandard
    return {
      addressText,
      timeWindow: timeWindow ? this.displayTimeWindow(timeWindow, timezone) : 'N/A',
      isWindowError: timeWindow ? false : true,
      locationName
    }
  }

  displayTimeWindow(time, timezone) {
    return DateUtil.displayTimeWindow(time, {
      timezone: timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    })
  }

  getLocationName(type) {

  }

  isWindowError(type) {

  }
  getWindow(type) {

  }

  onModelChanged() {
    this.displayInfo = {
      pickInfo: this.getPickInfo(),
      dropInfo: this.getDropInfo(),
      client: this.getClient(),
      routeId: this.getRouteId(),
      warpId: WarpId.showShipment(this.model.getWarpId())
    }
  }

}