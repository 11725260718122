<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div class="driver-current-location">
      <div>Driver's Current Location</div>
      <div *ngIf="displayInfo?.lastLocation" class="last-location">Last Location: {{displayInfo.lastLocation}}</div>
    </div>
    <div class="flex">
      <div class="left10"><span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span></div>
    </div>
  </div>
</ng-template>
<div>
  <form class="form-detail" [formGroup]="formInput" nz-form>
    <div nz-row [nzGutter]="{ sm: 16 }">
      <div nz-col nzSm="12" nzMd="12">
        <ng-container *ngFor="let key of ['city']">
          <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <div>
            <input id="city" [formControlName]="key" nz-input #driverLocation
              [nzAutocomplete]="autoComplete1"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)">
              <nz-autocomplete #autoComplete1>
                <nz-auto-option *ngFor="let item of listAddressAutoComplete[key]; let i = index" 
                  [nzValue]="getCityItem(item)" (selectionChange)="onLocationSelected($event, item)">
                  {{item}}
                </nz-auto-option>
              </nz-autocomplete>
          </div>
        </ng-container>
      </div>
      <div nz-col nzSm="12" nzMd="12">
        <ng-container *ngFor="let key of ['state']">
          <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" style="width: 100%;" nzShowSearch nzAllowClear 
                  [nzPlaceHolder]="getPlaceHolder(key)" 
                  [formControlName]="key">
                  <nz-option-group *ngFor="let group of allStates" [nzLabel]="group.label">
                    <nz-option *ngFor="let state of group.items"
                    [nzLabel]="getStateDesc(state)" [nzValue]="state.code">
                    </nz-option>
                  </nz-option-group>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
<ng-template [nzModalFooter]>
  <div class="form-add-driver-location">
    <div form-footer [onProgress]="onProgress" 
    [nzIconCancel]="null" [nzIconOK]="null"
    [canClickOK]="needUpdate && !onProgress"
    [canClickCancel]="!onProgress" labelOK="Add Location"
    (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
  </div>
</ng-template>
