<nz-skeleton [nzActive]="true" [nzLoading]="isLoading" [nzParagraph]="{rows: 12, width: ['100%','100%','100%','80%','100%','100%','100%','60%','100%','100%',]}">

  <div class="dispatch-route-detail">
    <div class="component-container">
      <div class="component-header">
        <div class="label">
          <b>Route Details</b> ({{this.displayInfo?.numberOfStops}} Stops)
        </div>
      </div>
      <div class="stop-list">
        <dispatch-route-stop *ngFor="let stop of this.displayInfo.stops; trackBy:trackBy"
          [stop]="stop" [issues]="displayInfo.issues" [stopNeedUpdateETA]="stopNeedUpdateETA"></dispatch-route-stop>
      </div>
    </div>
  </div>
</nz-skeleton>
