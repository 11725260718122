<div class="customer-info">
  <div class="component-body">
    <div *ngFor="let customer of data ; let i = index" class="customer-item" style="padding: 4px 0;">
      <a [routerLink]="[this.routeAdminClientList, customer.id]" target="_blank"><span
        class="customer-name">{{ customer.name }}: </span></a>
      <div nz-row [nzGutter]="{sm:16}" class="row-item" style="margin-left: 12px; padding-bottom: 10px ;">
        <div nz-col nzSm="0.5"></div>
        <div nz-col nzSm="32">
          <div nz-row [nzGutter]="{sm:16}">
            <div class="light title-colum" >Contact Name:</div>
            <span class="medium">{{ getFullName(customer) }}</span>
          </div>
          <div nz-row [nzGutter]="{sm:16}">
            <div class="light title-colum" >Phone Number:</div>
            <span class="medium">{{ getPhone(customer) }}</span>
          </div>
          <div nz-row [nzGutter]="{sm:16}">
            <div class="light title-colum" >Email:</div>
            <span class="medium">{{ getEmail(customer) }}</span>
          </div>
          <div nz-row [nzGutter]="{sm:16}">
            <div class="light title-colum" >Seller:</div>
            <span class="medium">{{ getSeller(customer) }}</span>
          </div>
          <div nz-row [nzGutter]="{sm:16}">
            <div class="light title-colum" >Account Owner:</div>
            <span class="medium">{{ getAccountOwner(customer) }}</span>
          </div>
          <div nz-row [nzGutter]="{sm:16}">
            <div class="light title-colum" >Customer Service Rep:</div>
            <span class="medium">{{ getCustomerServiceRep(customer) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
