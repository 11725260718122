<div class="adjust-shipment">
  <div class="wrap-container">
    <div class="title">Merge Routes</div>
    <ng-container *ngIf="step == 0">
      <div class="add-remove-shipment">
        <merge-route-shipment (listRouteForCancel)="getDataListRouteForCancel($event)"></merge-route-shipment>
      </div>
    </ng-container>
    <div class="sequence" *ngIf="step == 1">
      <edit-route-sequence [route]="route"></edit-route-sequence>
    </div>
  </div>
  <div class="bottom-bar">
    <div class="wrapper">
      <div class="button-controls">
        <button nz-button nzType="default" nzDanger class="cancel-btn" (click)="onCancel()">Cancel</button>
        <button *ngIf="step == 0" nz-button nzType="primary" class="cancel-btn" (click)="onNext()">Next</button>

        <button  *ngIf="step == 1" nz-button nzType="primary" class="submit-btn" (click)="onSubmit()" [disabled]="!canSubmit()"
          [nzLoading]="isSubmitting">Save</button>
      </div>
      <div class="info">
      </div>
    </div>
  </div>
</div>
