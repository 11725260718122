import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, Output, ViewChild } from '@angular/core';
import mapboxgl, { MapboxOptions, LngLatLike, Map as Mapbox, Marker, Popup, LngLatBounds, MarkerOptions, GeoJSONSource } from 'mapbox-gl';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { DeliveryInfo } from '@wearewarp/types/data-model';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import { AppConst } from '@app/const.generated';
import { BizUtil } from '@services/biz';
import { Log } from '@services/log';
import { getDashboard } from '@services/index';
import { Utils } from '@services/utils';
import { MapService } from '../../services/map.service';
import { RouteService } from '../../services/route.service';

interface MarkerInputData {
  shipment: any, pickInfo: DeliveryInfo, dropInfo?: DeliveryInfo
}

// const MARKER_COLOR_PICK = Const.MAP_LOCATION_COLOR_PICK;
// const MARKER_COLOR_DROP = Const.MAP_LOCATION_COLOR_DROP;
const MARKER_COLOR_PICK = '#7c4aed';    // purple
const MARKER_COLOR_DROP = '#73df54';    // green

@Component({
  selector: 'route-map',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../../../../styles/color-pallet.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteMap extends BaseComponent {
  public isEnabledDrawMode = false;
  public popupSelectionVisible: boolean = false;
  public selectedShipmentIds: any[] = []


  constructor(
    private mapService: MapService,
    private routingService: RouteService,
    private ngZone: NgZone
  ) {
    super();
  }

  ngOnInit(): void {
    this.mapService.createMap({ container: 'routing-problem-map' });
    this.init();
  }
  ngOnDestroy(): void {

  }

  init() {

  }

}