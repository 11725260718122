import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ETAIcon } from './eta';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ETAIcon
  ],
  exports: [
    ETAIcon,
  ],
  providers: [
  ]
})
export class CustomIconModule {}
