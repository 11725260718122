<div>
  <div class="content">
    Do you want to cancel shipment?<br />
    - If you just want to cancel the task in this route, and back the shipment status to <b>Pending</b>, please select
    <b>"No"</b>.<br />
    - If you want to cancel the shipment because of shipment has an issue or the customer cancels. Please choose
    <b>"Yes"</b>. Shipment status will become <b>Canceled</b>.
  </div>
  <div class="footer">
    <button nz-button class="btn" nzType="default" (click)="onClickCancelRouteOnly()" [nzLoading]="cancellingRouteOnly"
      [disabled]="cancellingRouteOnly || cancellingShipment" [nzType]="'default'">No, just cancel the task in this
      route</button>
    <button nz-button class="btn" nzType="primary" (click)="onClickCancelShipment()" [nzLoading]="cancellingShipment"
      [disabled]="cancellingRouteOnly || cancellingShipment">Yes, please cancel shipment</button>
  </div>
</div>