import { Component, EventEmitter, Input, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";

@Component({
  selector: 'dispatch-detail-screen',
  templateUrl: './index.html',
})
export class DispatchDetailScreen {
  constructor(
    public activatedRoute: ActivatedRoute,
  ) { }

  private subscription: Subscription = new Subscription();
  public routeId: string;

  ngOnInit(): void {

    this.subscription.add(
      this.activatedRoute.params.subscribe(async params => {
        this.routeId = params.id;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
