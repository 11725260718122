import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import RouteEntity from "../../entity/RouteEntity";

@Component({
  selector: "[dispatch-customer]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DispatchCustomerInfo extends BaseComponent {
  data: any[] = [];
  constructor(protected activatedRoute: ActivatedRoute, protected dispatchService: DispatchService) {
    super();
  }
  route: RouteEntity;
  ngOnInit() {
    super.ngOnInit();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        this.fetchCustomerInfo();
      })
    );
  }

  private getPersonName(person) {
    if (!person) return "N/A";
    if (person?.firstName == "N/A" && person?.lastName == "N/A") return "N/A";
    if (person?.firstName || person?.lastName) {
      return `${person?.firstName == "N/A" ? "" : person?.firstName} ${person?.lastName == "N/A" ? "" : person?.lastName}`.trim();
    }
    if (person?.fullName) {
      return person?.fullName;
    }
    return "N/A";
  }

  getFullName(customer) {
    return customer ? this.getPersonName(customer.primaryContact) : "N/A";
  }

  getSeller(customer) {
    return customer ? this.getPersonName(customer.seller) : "N/A";
  }

  getAccountOwner(customer) {
    return customer ? this.getPersonName(customer.accountOwner) : "N/A";
  }

  getCustomerServiceRep(customer) {
    return customer ? this.getPersonName(customer.customerServiceRep) : "N/A";
  }

  getPhone(customer: any) {
    if (!customer) return "N/A";
    if (customer.primaryContact?.phone) {
      return this.displayPhone(customer.primaryContact?.phone);
    }
    return "N/A";
  }
  getEmail(customer: any) {
    if (!customer) return "N/A";
    if (customer.primaryContact?.email) {
      return customer.primaryContact?.email;
    }
    return "N/A";
  }
  fetchCustomerInfo() {
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.route.getId()}/customer-contacts`;
    this.api.GET(url).subscribe((res: any) => {
      this.data = res.data?.list_data;
    });
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
