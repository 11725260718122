import { Task } from "@wearewarp/types/data-model";
import { Const } from "@wearewarp/universal-libs";
import { RouteService } from "../services/route.service";
import { getInjector } from "@services/injector";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import ShipmentEntity from "./ShipmentEntity";

export default class TaskEntity {
  private data: Task;
  private cost: any;
  private stopIndex: number;

  private routeService: RouteService;
  public displayInfo: any = {};

  constructor() {
    const injector = getInjector();
    this.routeService = injector.get(RouteService);
  }

  public setRouteService(routeService) {
    if (routeService) {
      this.routeService = routeService;
    }
    return this;
  }

  init(task: Task) {
    this.data = task;
    if(!this.data.id) this.data.id = Utils.generateULID();
    this.refreshDisplay();
    return this;
  }

  initByShipment(shipment: ShipmentEntity, type) {
    const deliveryInfo = type == Const.TaskType.DROPOFF ? shipment.getDropInfo() : shipment.getPickInfo();
    return this.init({
      type: type,
      shipmentId: shipment.getId(),
      info: deliveryInfo,
      location: {
        longitude: deliveryInfo?.addr?.metadata?.longitude,
        latitude: deliveryInfo?.addr?.metadata?.latitude,
      }
    })
  }

  refreshDisplay() {
    this.displayInfo = {
      type: this.getType(),
      id: this.getId(),
      status: this.getStatus(),
      addressText: this.getAddressText(),
      locationName: this.getWarehouseName(),
      valid: this.isValid(),
      stopIndex: this.getStopIndex(),
      cost: this.getCost()
    }
  }

  getId() {
    return this.data.id;
  }

  getType() {
    return this.data.type
  }
  getWarpId() {
    return this.getShipment()?.getWarpId()
  }

  getStatus() {
    return this.data.status || Const.TaskStatus.created
  }

  getArrivedTime() {
    if (![Const.TaskStatus.arrived, Const.TaskStatus.succeeded].includes(<any>this.getStatus())) return;
    const log = this.data.statusChangeLog[Const.TaskStatus.arrived]
    return log?.changeWhen
  }

  getDepartedTime() {
    if (![Const.TaskStatus.succeeded].includes(<any>this.getStatus())) return;
    const log = this.data.statusChangeLog[Const.TaskStatus.succeeded]
    return log?.changeWhen
  }
  getETA() {
    return this.data.etaTime
  }

  getShipmentId() {
    return this.data.shipmentId
  }

  getShipment() {
    return this.routeService.getShipmentById(this.getShipmentId())
  }

  getWarehouseName() {
    return this.data?.info?.locationName || ""
  }
  getAddressText() {
    return MasterData.getAddressText(this.data?.info?.addr)
  }

  getLocation() {
    return this.data?.location
  }

  getLocationLngLat() {
    return [this.data.location.longitude, this.data.location.latitude]
  }

  setCost(cost) {
    this.cost = cost;
    return this;
  }

  getCost() {
    return this.cost
  }

  setStopIndex(stop) {
    this.stopIndex = stop;
    return this;
  }
  getStopIndex() {
    return this.stopIndex
  }
  getAddress() {
    return this.data?.info?.addr
  }
  getInfo() {
    return this.data?.info
  }

  canSameStop(task: TaskEntity) {
    return task.getType() == this.getType() && Utils.isSameAddress(this.getAddress(), task.getAddress())
  }

  getRoute() {
    return this.routeService.getRoute();
  }

  isValid() {
    if (this.getType() == Const.TaskType.PICKUP) return true;
    const route = this.getRoute();
    const tasks = route?.getTasks();
    if (!route || !tasks) return false;

    const taskIndex = tasks.findIndex(item => item.getType() == this.getType() && item.getShipmentId() == this.getShipmentId());
    const pickupIndex = tasks.findIndex(item => item.getType() == Const.TaskType.PICKUP && item.getShipmentId() == this.getShipmentId());
    if (taskIndex == -1) return true; //case thêm task mới
    if (pickupIndex != -1 && taskIndex > pickupIndex) return true;

    return false;
  }

  toJSON() {
    return {
      ...this.data
    }
  }
}
