import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { RouteService } from "../../services/route.service";
import RouteEntity from "../../entities/RouteEntity";
import { Subscription } from "rxjs";
import TaskEntity from "../../entities/TaskEntity";
import { Const } from "@wearewarp/universal-libs";
import { MapService } from "../../services/map.service";
import { Utils } from "@services/utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { SortedTaskByShipmentId } from "@wearewarp/types/rest-api/admin";
import StopEntity from "../../entities/StopEntity";

@Component({
  selector: 'edit-route-sequence',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class EditRouteSequence extends BaseComponent {
  private _route: RouteEntity;

  public isLoading: boolean = true;
  public isSubmitting: boolean = false;
  public mode: string = 'tasks'

  public cost = {
    distance: 'N/a',
    time: 'N/a'
  }

  constructor(
    private mapService: MapService
  ) {
    super()
  }

  // @Input() onFinish: Function
  @Input() set route(route) {
    this._route = route;
    this.tasks = this._route.getTasks();
    this.stops = this._route.getStops();
    this.calculateCost();
  }
  get route() {
    return this._route
  }

  public tasks: TaskEntity[] = []
  public stops: StopEntity[] = []


  ngOnInit(): void {
    this.mapService.mapReady.subscribe(() => {
      this.reDraw()
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  async tasksChanged() {
    await this.route.updateTasks(this.tasks);
    this.stops = this.route.getStops();
    this.reDraw();
    this.calculateCost();
  }

  async stopsChanged() {
    let tasks = [];
    for (let stop of this.stops) {
      const stopTasks = stop.getTasks();
      tasks = tasks.concat(stopTasks);
    }
    this.tasks = tasks
    await this.route.updateTasks(this.tasks);
    this.reDraw();
    this.calculateCost();

    this.stops = this.route.getStops();
   
  }

  reDraw() {
    if (!this.mapService.isReady()) return;

    this.mapService.reset();
    this.tasks.map(task => {
      this.mapService.addMarker({
        location: task.getLocationLngLat(),
        data: task.getInfo()
      }).setPaint({
        color: this.mapService.getMakerColor(task.getType())
      })
    });

    this.mapService.addLine({
      coordinates: this.tasks.map(task => task.getLocationLngLat())
    }).setPaint({
      'line-color': '#7c4aed',
      'line-width': 2
    })
    this.mapService.fitBounds()
  }

  calculateCost() {
    let distance = 0;
    let time = 0;
    for (let task of this.tasks) {
      distance += task.getCost()?.distance ?? 0
      time += task.getCost()?.time ?? 0
    }
    this.cost = {
      distance: (distance / 1609.34).toFixed(2).toLocaleString() + ' miles',
      time: (time / 3600).toFixed(2).toLocaleString() + ' hours'
    }
  }
}