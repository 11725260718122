<div class="flex bottom5 title">
  <div class="flex flex1">
    <a class="warp-id inherit" [routerLink]="[routeAdminDispatchList, model.getId()]"
      target="_blank">Route: {{displayInfo.routeId}}</a>
  </div>
  <div class="pallet-desc medium">Shipments: {{displayInfo.countShipment}}</div>
</div>

<div class="flex">
  <div class="icon-direction noselect">
    <div class="dot">⬤</div>
    <div class="line"></div>
    <div class="dot triangle">▼</div>
  </div>
  <div class="flex1">
    <div class="pick-addr" nz-popover [nzPopoverContent]="" nzPopoverPlacement="right">
      {{displayInfo?.pickInfo?.addressText}}</div>
    <div class="pick-time">{{displayInfo?.pickInfo?.timeWindow}}</div>
    <div class="pick-addr" nz-popover [nzPopoverContent]="" nzPopoverPlacement="right">
      {{displayInfo.dropInfo?.addressText}}</div>
    <div class="pick-time">{{displayInfo?.dropInfo?.timeWindow}}</div>
  </div>
</div>
