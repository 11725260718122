import { NgModule } from '@angular/core';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';

import { CommonModule } from '@angular/common';
import { EventsContainer } from '.';
import { RawEventJsonContainer } from './raw';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { EventDetailContainer } from './detail';

@NgModule({
    imports: [
        NzSelectModule,
        NzCheckboxModule,
        CommonModule,
        NzIconModule,
        NzButtonModule,
        NgxJsonViewerModule,
        NzDividerModule,
    ],
    declarations: [
        EventsContainer,
        RawEventJsonContainer,
        EventDetailContainer,
    ],
    exports: [
        EventsContainer,
    ],
    providers: []
})

export class EventsModule { }