import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
  selector: "app-email-bol",
  templateUrl: "./email-bol.component.html",
  styleUrls: ["./email-bol.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EmailBolComponent extends BaseFormDialog1 implements OnInit {
  protected formGroupDeclaration: FormGroupDeclaration = {
    sendToCustomer: { label: "Customer" },
    customerEmails: { label: "Email" },
    sendToCarrier: { label: "Customer" },
    carrierEmail: { label: "Email" },
    carrierName: { label: "Email" },
    remark: { label: "Remark" },
  };
  constructor() {
    super();
  }

  @Input() jobId;
  @Input() bolFileId;
  @Input() carrier;
  @Input() clients;
  public isError = false;
  public isLoading = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;
  ngOnInit(): void {
    this.getData();
  }

  hasSendTo(type) {
    return this.getItemValue(type);
  }

  get canClickOK() {
    let data: any = this.getFormData_JSON(true);
    //nếu chọn customer nhưng khôngnhập email customer
    if (data.sendToCustomer && data.customerEmails?.length == 0) return false;
    //nếu chọn carrier nhưng không nhập email carrier
    if (data.sendToCarrier && !data.carrierEmail) return false;
    //nếu không chọn cả carrier & customer
    if (!data.sendToCarrier && !data.sendToCustomer) return false;

    return true;
  }

  getData() {
    this.createFormInput();

    //lấy luôn dữ liệu từ job để đẩy vào form, không cần fetch lại API
    let basicInfo = this.carrier?.basicInfo || {};
    let carrierContacts = basicInfo?.contacts || [];
    let carrierContact = carrierContacts[0] || {};
    let clients = this.clients || [];
    let clientEmails = [];
    clients.map((client) => {
      let contact = client.primaryContact || {};
      let email = contact.email;
      if (!email) return;
      clientEmails.push(email);
    });

    if (carrierContact.email) {
      this.setItemValue("sendToCarrier", true);
      this.setItemValue("carrierName", basicInfo.name || "Carrier");
      this.setItemValue("carrierEmail", carrierContact.email);
      this.getItemByKey("carrierEmail").disable();
    }

    //luôn set giá trị cho array customerEmails để tránh null
    this.setItemValue("customerEmails", clientEmails);
    if (clientEmails.length > 0) {
      this.getItemByKey("customerEmails").disable();
      this.setItemValue("sendToCustomer", true);
    }
  }

  onEditEmail(formControlName) {
    //khi click btn edit trên form (cạnh input) thì enable để user có thể edit input.
    if (this.getItemByKey(formControlName).enabled)
      this.getItemByKey(formControlName).disable();
    else this.getItemByKey(formControlName).enable();
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    //ở lần ấn đầu, hiển thị form confirm thay vì submit luôn.
    if (!this.inConfirm) {
      this.confirmData = json;
      this.inConfirm = true;
      return;
    }

    //lần ấn tiếp theo (confirm) thì call API
    let contacts = [];
    if (json.sendToCarrier) {
      contacts.push({
        name: json.carrierName,
        email: json.carrierEmail,
        type: "carrier",
      });
    }

    if (json.sendToCustomer) {
      let customerList = json.customerEmails.map((email) => ({
        name: "Customer",
        email: email,
        type: "customer",
      }));
      contacts = [...contacts, ...customerList];
    }

    const data = {
      contacts: contacts,
      remark: json.remark,
      jobId: this.jobId,
      fileId: this.bolFileId,
      type: "bol",
    };

    this.startProgress();
    this.isLoading = true;
    this.api.POST(`${Const.APIURI_JOBS}/send_file_email`, data).subscribe(
      (resp) => {
        this.stopProgress();
        this.isLoading = false;
        this.closeDialog();
        this.showSuccess("BOL email has been sent successfully.");
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
        this.closeDialog();
      }
    );
  }
  onBtnCancel() {
    //back to form
    if (this.inConfirm) {
      this.inConfirm = false;
      return;
    }
    //close dialog
    this.closeDialog();
  }
}
