import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { DriverAppStatus } from './component';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
  ],
  declarations: [
    DriverAppStatus
  ],
  exports: [
    DriverAppStatus
  ]
})
export class ModuleDriverAppStatus {}