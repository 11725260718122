<div class="merge-route">
  <div class="add-new flex">
    <nz-select nzMode="multiple" nzBackdrop="true" [(ngModel)]="newListRoute" name="value" nzPlaceHolder="Search Route Id to Add" nzAllowClear
    nzShowSearch [nzServerSearch]="true" (nzOnSearch)="searchNewRoute($event)" nzOptionHeightPx="60">
    <ng-container *ngFor="let route of newRouteOptions">
      <nz-option [nzDisabled]="checkRouteSame(route)" nzCustomContent [nzLabel]="route.getCode()" [nzValue]="route">
        <div search-route-item [model]="route" [canRemove]="false"></div>
      </nz-option>
    </ng-container>
    <nz-option nzLabel="Can be multiple Route IDs" nzValue="" nzDisabled></nz-option>
    </nz-select>
    <div class="btn-add">
      <button [disabled]="!shouldShowBtnMerge()" nz-button nzType="primary" (click)="onBtnMerge()">
        <i nz-icon nzType="plus"></i>
        Add
      </button>
    </div>
  </div>
  <div class="list-route-selected">
    <nz-table #nzTable [nzData]="shipments" nzBordered="true" nzSize="small" [nzFrontPagination]="false"
    [nzShowPagination]="false" [nzLoading]="isLoading">
      <thead>
        <tr>
          <th>Shipment ID</th>
          <th>Pickup Address</th>
          <th>Dropoff Address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let shipment of nzTable.data">
          <td>{{shipment?.getWarpId()}}</td>
          <td>
            <b>{{shipment?.getPickInfo()?.locationName}}</b><br />
            {{shipment?.getPickInfo() | addressText}}
          </td>
          <td>
            <b>{{shipment?.getDropInfo()?.locationName}}</b><br />
            {{shipment?.getDropInfo() | addressText}}
          </td>
          <td>
            <button nzDanger nz-button nzType="text" nz-popconfirm
            nzPopconfirmTitle="Are you sure to remove this shipment from the route?"
            (nzOnConfirm)="onRemoveShipment(shipment)">
            <i nz-icon nzType="delete"></i>
          </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
