<form class="form-detail" nz-form>
  <div class="flex">
    <div class="f20 bottom20 flex1">Reference Number</div>
  </div>
  <input
    nz-input
    maxlength="18"
    class="top20 add-custom-tracking-content"
    placeholder="Enter reference number here..."
    [(ngModel)]="referenceNumber"
    [ngModelOptions]="{standalone: true}"
  />
</form>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="true"
  [canClickOK]="true"
  labelOk="Save"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
