<div class="form-detail" nz-form nzLayout="vertical">
    <nz-form-item>
        <nz-form-label nzRequired nzFor="email">Email Address</nz-form-label>
        <nz-form-control nzErrorTip="The input is not valid E-mail!">
            <nz-input-group>
                <nz-select nzBackdrop="true" [(ngModel)]="recipients" nzMode="tags"
                    nzPlaceHolder="Please enter customer's email" style="width: 100%">
                </nz-select>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label nzFor="subject">Subject</nz-form-label>
        <nz-form-control>
            <input nz-input [(ngModel)]="subject" id="subject" placeholder="Subject" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label nzFor="content">Content</nz-form-label>
        <nz-form-control>
            <textarea nz-input [(ngModel)]="content" id="content" placeholder="write a message..." rows="10"></textarea>
        </nz-form-control>
    </nz-form-item>
    <div class="button-submit">
        <button nz-button type="button" nzType="primary" (click)="sendEmail()" [nzLoading]="sending">Send</button>
    </div>
</div>