import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";

@Component({
  selector: '[driver-down-time]',
  templateUrl: './index.html',
  styleUrls: ['../../../../../dialogs/dialogs.scss', '../../../../../../styles/form-v2.scss']
})
export class DriverDownTime extends BaseFormDialog1 {
  public static get declaration(): FormGroupDeclaration {return {
    from: {label: 'Start Time', type: 'date', required: true},
    to: {label: 'End Time', type: 'date', required: true},
  }}
  protected formGroupDeclaration: FormGroupDeclaration = DriverDownTime.declaration;

  @Input() timezone;

  protected beforeBindModel(model: any) {
    let data = {}
    if (model?.from && model?.to) {
      data['from'] = DateUtil.convertLocalTime2(model.from, this.timezone);
      data['to'] = DateUtil.convertLocalTime2(model.to, this.timezone);
    }
    return data;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onSave: (data) => void = () => {}

  onBtnSave() {
    let json = this.formData_JSON(true);
    let fromDateTime = DateUtil.convertLocalTime(json['from'], this.timezone).toISOString();
    let toDateTime = DateUtil.convertLocalTime(json['to'], this.timezone).toISOString();
    const data = {
      from: fromDateTime,
      to: toDateTime
    }
    this.onSave(data);
    this.closeDialog();
  }

  onBtnCancel() {
    this.closeDialog();
  }

  getTimezoneShort() {
    return DateUtil.timezoneStandardToUsShort(this.timezone);
  }
}
  