<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>
<div *ngIf="isLoading && inConfirm" class="text-center bold">Email sending in progress. Please wait a moment...</div>

<div class="confirm-content" *ngIf="!isLoading && inConfirm">
  <div class="email-bol-popup">
    <div class="title">Email BOL</div>
    <div class="description">Recipients will receive an email with a link to download BOL.</div>
  </div>
  <div class="confirm-content">

    <div nz-row class="bottom15">
      <div nz-col nzSpan="24"><b>Sender:</b></div>
      <div nz-col nzSpan="24">{{authUserFullName}} / {{authUserEmail}}</div>
    </div>

    <div nz-row *ngIf="confirmData?.sendToCustomer" class="bottom15">
      <div nz-col nzSpan="24"><b>Customer's Email Address:</b></div>
      <div nz-col nzSpan="24">{{confirmData?.customerEmails?.join(", ")}}</div>
    </div>
    <div nz-row *ngIf="confirmData?.sendToCarrier" class="bottom15">
      <div nz-col nzSpan="24"><b>Carrier's Email Address:</b></div>
      <div nz-col nzSpan="24">{{confirmData?.carrierEmail}}</div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24"><b>Remark:</b></div>
      <div nz-col nzSpan="24">{{confirmData?.remark}}</div>
    </div>
  </div>
</div>

<form *ngIf="!isLoading && !inConfirm" class="form-detail" [formGroup]="formInput" nz-form nzLayout="vertical">
  <div class="email-bol-popup">
    <div class="title">Email BOL</div>
    <div class="description">Recipients will receive an email with a link to download BOL.</div>
  </div>
  <div nz-row class="bottom10">
    <div nz-col nzSpan="24">
      <label nz-checkbox formControlName="sendToCustomer">
        <span>Customer</span>
      </label>
    </div>
  </div>
  <nz-form-item *ngIf="hasSendTo('sendToCustomer')">
    <nz-form-label nzRequired nzFor="email">Email Address</nz-form-label>
    <nz-form-control nzErrorTip="The input is not valid E-mail!">
      <nz-input-group [nzAddOnAfter]="editCustomerEmailBtnTemp">
        <nz-select nzBackdrop="true" nzMode="tags" nzPlaceHolder="Please enter customer's email" formControlName="customerEmails"
          style="width: 100%">
        </nz-select>

      </nz-input-group>
      <ng-template #editCustomerEmailBtnTemp>
        <a (click)="onEditEmail('customerEmails')">
          Edit
        </a>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <div nz-row class="bottom10">
    <div nz-col nzSpan="24">
      <label nz-checkbox formControlName="sendToCarrier">
        <span>Carrier</span>
      </label>
    </div>
  </div>

  <nz-form-item *ngIf="hasSendTo('sendToCarrier')">
    <nz-form-label nzRequired nzFor="carrierEmail">Email Address</nz-form-label>
    <nz-form-control nzErrorTip="The input is not valid E-mail!">
      <nz-input-group [nzAddOnAfter]="editCarrierEmailBtnTemp">
        <input nz-input formControlName="carrierEmail" id="carrierEmail" />

      </nz-input-group>
      <ng-template #editCarrierEmailBtnTemp>
        <a (click)="onEditEmail('carrierEmail')">
          Edit
        </a>
      </ng-template>
    </nz-form-control>
  </nz-form-item>


  <nz-form-item>
    <nz-form-label nzFor="remark">Remark</nz-form-label>
    <nz-form-control>
      <textarea nz-input formControlName="remark" id="remark" placeholder="Write a message..."></textarea>
    </nz-form-control>
  </nz-form-item>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && canClickOK" [canClickCancel]="!onProgress"
  [labelOK]="inConfirm? 'Confirm': 'Email BOL'" (onOK)="onBtnSave()" (onCancel)="onBtnCancel()"></div>
