import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import { Const, WarpId } from "@wearewarp/universal-libs";
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { RouteService } from "../../services/route.service";
import { Subscription } from "rxjs";
import TaskEntity from "../../entities/TaskEntity";

@Component({
  selector: 'route-task-list',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class RouteTaskList {

  @Input() tasks: TaskEntity[] = []
  @Output() tasksChanged = new EventEmitter<any>();
  constructor(private routeService: RouteService) {
  }

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.tasksChanged.emit(this.tasks)
  }
}