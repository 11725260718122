import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { Const } from "@const/Const";
@Component({
  selector: "add-custom-tracking",
  templateUrl: "./index.html",
  styleUrls: [
    "../../../../../dialogs/dialogs.scss",
    "../../../../../../styles/row-col.scss",
    "./index.scss",
  ],
})
export class AddCustomTracking extends BaseFormDialog1 {
  @Input() jobId;
  @Input() links:string[];
  link: String;
  ngOnInit(): void {
    this.link = this.links ? this.links[0] : undefined;
  }

  onBtnSave(): void {
    this.setEnableFormGroup(false);
    this.startProgress();
    let data = [];
    if(this.link?.length > 0) data.push(this.link);
    this.api
      .PUT(`${Const.APIURI_JOBS}/${this.jobId}/custom_tracking`, {
        links: data,
      })
      .subscribe(
        (resp) => {
          Log.d("addCustomTracking done ", resp);
          this.onUpdateSuccess(resp);
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
        }
      );
  }
}
