import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import TaskEntity from "../../entities/TaskEntity";

@Component({
  selector: 'route-stop-icon',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopIcon {

  @Input() task: TaskEntity;
  @Input() tasks: TaskEntity[];
  @Input() changeDetect: any; //biến này sử dụng để detectchange.

  shouldShowStop() {
    const taskIndex = this.tasks.findIndex(t => t.getId() == this.task.getId());
    if (taskIndex == 0) return true;
    const prevTask = this.tasks[taskIndex - 1];
    if (prevTask.getStopIndex() == this.task.getStopIndex()) return false;
    return true;
  }
}