import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseFormItem } from '@app/admin/base/form-item';
import { getFeatureFlags } from '@services/feature-flag.service';
import { DispatchService } from '../../dispatchService';
import { DriverMessage } from '../driver-message';
@Component({
  selector: 'dispatch-sidebar-menu',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class DispatchSidebarMenu extends BaseFormItem implements OnInit {
  @Input() jobId: string;

  public isLoading: boolean = true;
  public showEvents: boolean = false;
  public driverMessageNumber: number = 0;

  @ViewChild('driverMessage') driverMessage: DriverMessage;

  constructor(private dispatchService: DispatchService) {
    super();
  }

  ngOnInit(): void {

    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
    this.subscription.add(
      this.dispatchService.countDriverMessage$.subscribe((value) => {
        this.driverMessageNumber = value;
      })
    )
    getFeatureFlags().isFlagSetNonBlocking('SHOW_EVENT').subscribe((res) => {
      if (res) {
        this.showEvents = true;
      }
    })
  }

  startIntervalDriverMessage() {
    this.driverMessageNumber = 0;
    this.driverMessage?.startIntervalDriverMessage();
  }

  stopIntervalDriverMessage() {
    this.driverMessage?.stopIntervalDriverMessage();
  }
}