import { Component, EventEmitter, Input, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";

@Component({
  selector: 'dispatch-detail-empty',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class DispatchDetailEmpty {
  constructor(
    public activatedRoute: ActivatedRoute,
  ) { }

  private subscription: Subscription = new Subscription();

  ngOnInit(): void {

    this.subscription.add(
      this.activatedRoute.params.subscribe(async params => {

      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
