import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzPopoverModule } from "ng-zorro-antd/popover";

// import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { SharedModule } from "../shared/shared.module";
import { CustomIconModule } from "../components/icon/icon.module";
import { FormInputUploadImagesModule } from "../base/form-input-upload-images/module";
import { NzImageModule } from "ng-zorro-antd/image";

import { DrawerModule } from "@app/drawers/drawer.module";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ModalHelper, UpdateTaskStatusModule, ModuleUpdateEta } from '@wearewarp/ng-antd'
import { EditRouteSequence } from "./components/edit-sequence";
// import { RouteService } from "./services/route.service";
import { RouteTaskList } from "./components/task-list";
import { TaskWarpIdPipe } from './pipes/taskWarpId.pipe'
import { StopIcon } from "./components/stop-icon";
import { MapService } from "./services/map.service";
import { RouteMap } from "./components/map";
import { AdjustRouteShipment } from "./components/adjust-shipment";
import { EditRouteSequenceScreen } from "./screens/edit-sequence";
import { AdjustRouteShipmentScreen } from "./screens/adjust-shipment";
import { SearchShipmentItem } from "./components/shipment-item";
import { AddressTextPipe } from "./pipes/addressText.pipe";
import { RouteStopList } from "./components/stop-list";
import { MergeRouteShipmentScreen } from "./screens/merge";
import { MergeRouteShipment } from "./components/merge";
import { ValidateShipmentModal } from "./components/validate-shipment-modal";
import { ValidateMergeRoute } from "./components/validate-merge-route-modal";
import { DisplayShipmentWarpID } from "./components/display-shipment-warpid";
import { SearchRouteItem } from "./components/route-item";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzPaginationModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzTypographyModule,
    NzDrawerModule,
    NzSpaceModule,
    NzAvatarModule,
    NzImageModule,
    NzDropDownModule,
    NzPopoverModule,
    SearchBoxModule,
    FormInputUploadImagesModule,
    DetailModule,
    SelectBySearchingModule,
    FormInputCostModule,
    SharedModule,
    NzBadgeModule,
    DrawerModule,
    CustomIconModule,
    DragDropModule,
  ],
  declarations: [
    SearchShipmentItem,
    EditRouteSequenceScreen,
    AdjustRouteShipmentScreen,
    EditRouteSequence,
    AdjustRouteShipment,
    RouteTaskList,
    RouteStopList,
    StopIcon,
    RouteMap,
    TaskWarpIdPipe,
    AddressTextPipe,
    MergeRouteShipmentScreen,
    MergeRouteShipment,
    ValidateShipmentModal,
    ValidateMergeRoute,
    DisplayShipmentWarpID,
    SearchRouteItem
  ],
  exports: [
    EditRouteSequenceScreen,
    AdjustRouteShipmentScreen
  ],
  providers: [
    ModalHelper,
    // RouteService,
    MapService
  ]
})
export class RouteModule { }
