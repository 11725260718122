import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { startOfDay, endOfDay } from 'date-fns'
import { Utils } from "@services/utils";
import { ApiService } from "@services/api.service";
import { MasterData } from "@services/master.data";
import { DialogService } from "@dialogs/dialog.service";
import { ReAssignDispatcherComponent } from "@app/admin/dispatch/components/re-assign-dispatcher";
/**
 * Dùng chung cho dispatch, carrier-sales
 */
@Component({
  selector: '[list-header-filter-v3]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../../../styles/form-v2.scss']
})
export class DispatchListHeaderFilterV3 extends BaseFormItem {
  @Input() getDefaultFilter: () => any = () => { return DispatchListHeaderFilterV3.defaultFilter }
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  public static get defaultFilter() { return { status: DispatchListHeaderFilterV3.defaultStatus } }
  private static get defaultStatus() { return [Const.JobStatus.created, Const.JobStatus.inProgress] }
  private get declaration(): FormGroupDeclaration {
    return {
      status: { label: 'Status', placeHolder: 'Select', notAcceptEmpty: true },
      sort: { label: 'Sort', placeHolder: 'Select', notAcceptEmpty: true },
      clientId: { label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select' },
      shipmentType: { label: 'Shipment Type', placeHolder: 'Select', notAcceptEmpty: true },
      carrierId: { label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select' },
      pickupState: { label: 'Pickup State', placeHolder: 'Select Pick', notAcceptEmpty: true },
      dropoffState: { label: 'Delivery State', placeHolder: 'Select Drop', notAcceptEmpty: true },
      // equipment: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      vehicleType: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      fromDate: { label: 'From date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
      toDate: { label: 'To date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
      picId: { label: 'Dispatcher', placeHolder: 'Select', notAcceptEmpty: true },
      carrierSaleRepId: { label: 'Carrier Sales Rep', placeHolder: 'Select', notAcceptEmpty: true },
      clientSaleRepId: { label: 'Client Sales Rep', placeHolder: 'Select', notAcceptEmpty: true }
      // hotlist: {label: 'Hotlist', placeHolder: 'Select', notAcceptEmpty: true},
    }
  };
  
  private get declarationForSysadmin(): FormGroupDeclaration {return {
    jobId: {label: 'Job ID', notAcceptEmpty: true},
  }}

  protected formGroupDeclaration: FormGroupDeclaration = {};

  public listDispatchers = [];
  dispatchersHaveJobs = [];
  listDispatcherForDropdown: any[] = [];
  public listCarriers = [];
  public listCarrierSales = [];
  public listClientSales = [];
  public listTimezones = DateUtil.listTimezones;
  public allStatuses = Const.JobStatusArray;
  public sortTypes = [
    { name: 'Pickup Date - Ascending', value: 'pickDt.time,1' },
    { name: 'Pickup Date - Descending', value: 'pickDt.time,-1' },
    { name: 'Delivery Date - Ascending', value: 'dropDt.time,1' },
    { name: 'Delivery Date - Descending', value: 'dropDt.time,-1' },
  ];
  public listShipmentTypes = Const.ShipmentTypesArray;
  public hotlistOptions = [
    'carrierAssignment',
    'etaToPickup',
    'pickupSuccessful',
    'etaToDelivery',
    'deliverySuccessful',
    'mustArriveAtPickupOnTime',
    'mustArriveAtDeliveryOnTime'
  ]
  public search: string = null
  public loaded: number = 0
  public page: number = 1
  public isExporting = false;
  public allStates = MasterData.getStatesUS();
  public isExpanded = false;
  public selectedStatus = {};
  public countStatusData = {};
  public offsetCustomerFilter = 0;
  public offsetSortFilter = 0;
  private timeOut = null;
  public lastUpdated;
  public requestId;
  public observerResize;

  public countriesStates = MasterData.getCountriesStates_forSelectGroup();

  @ViewChild('form') form: ElementRef<HTMLFormElement>;
  @ViewChild('customerFilter') customerFilter: ElementRef<HTMLElement>;
  @ViewChild('sortFilterDispatch') sortFilterDispatch: ElementRef<HTMLElement>;
  // //window resize thì tính toán lại chiều cao của dispatch window
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.toggleDetailContainerHeight();
  }
  constructor(
    protected activatedRoute: ActivatedRoute, private eRef: ElementRef,
  ) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      this.search = p.search
      this.loaded = p.loaded || 0
      this.page = p.page || 1
    })
  }

  @Input() searchPlaceHolder = 'Search by Load ID, WARP ID, Ref Number';

  ngOnInit(): void {
    if (this.isSysAdmin) {
      this.formGroupDeclaration = {...this.declarationForSysadmin, ...this.declaration};
    } else {
      this.formGroupDeclaration = this.declaration;
    }
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      this.model = JSON.parse(params.filter);
    } else if (!params.search) {
      // Nếu ko có cả filter và search thì mới gán filter mặc định
      this.model = this.getDefaultFilter(); //DispatchListHeaderFilterV3.defaultFilter;
    }
    if (params.sort) {
      this.model.sort = params.sort;
    }
    super.ngOnInit();
    this.fetchListCarriers();
    this.fetchListDispatchers();
    this.fetchListCarrierSales();
    this.fetchListClientSales();
    this.countStatus();
    this.initResizeSensor();
  }

  ngOnDestroy() {
    this.destroyResizeSensor()
  }

  ngAfterViewChecked() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      if (!this.isExpanded && this.getOffsetTopCustomerFilter == this.getOffsetTopSortFilter && this.offsetCustomerFilter != this.getOffsetTopCustomerFilter) {
        this.offsetCustomerFilter = this.getOffsetTopCustomerFilter;
      }
    }, 100);
  }
  private get getOffsetTopCustomerFilter() {
    if (this.customerFilter?.nativeElement?.offsetTop) {
      return this.customerFilter.nativeElement.offsetTop;
    }
    return 0;
  }
  private get getOffsetTopSortFilter() {
    let topElement = 0;
    if (this.sortFilterDispatch.nativeElement?.offsetTop) {
      return this.sortFilterDispatch.nativeElement.offsetTop;
    }
    return topElement;
  }

  getVehicleType() {
    if (this.getItemValue('vehicleType')) {
      return { code: this.getItemValue('vehicleType')} 
    }
    return this.getItemValue('vehicleType');
    if (this.model?.vehicleType) {
      return { code: this.model.vehicleType}
    }
    return null;
  }

  initResizeSensor() {
    const elem = document.querySelector('.dispatch-container .list-header .box-container');
    if(!elem) return;
    this.observerResize = new ResizeObserver(() => this.toggleDetailContainerHeight())
    this.observerResize.observe(elem)
  }
  destroyResizeSensor(){
    const elem = document.querySelector('.dispatch-container .list-header .box-container');
    if(!elem) return;
    this.observerResize?.unobserve(elem)
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    this.toggleDetailContainerHeight();
  }
  toggleDetailContainerHeight() {
    const headerDom = document?.querySelector(".dispatch-container .list-header");
    const headerHeight = headerDom?.clientHeight;
    const windowHeight = window.innerHeight;
    const contentHeaderHeight = headerDom?.querySelector(".box-container").clientHeight;
    const contentHeaderNewHeight = this.isExpanded ? contentHeaderHeight : 90;
    const containerHeight = windowHeight - 120 - contentHeaderNewHeight;
    headerDom?.setAttribute("style", `height: ${contentHeaderNewHeight}px`);
    document?.querySelectorAll(".dispatch-container .dispatch-dynamic-height").forEach((elem => elem.setAttribute("style", `height: ${containerHeight}px;`)))
  }

  clearFilter() {
    for (let key of this.formInputKeys) {
      this.setItemValue(key, null);
    }
    this.selectedStatus = {};
    this.search = null;
    this.needCarrierChecked = false;
    this.needDispatcherChecked = false;
    this.needPOPChecked = false;
    this.page = 1
    this.loadData()
  }

  doSearch(s) {
    this.search = s
    this.page = 1
    this.loadData()
  }

  onRefresh() {
    this.onReload.emit()
    this.loadData()
  }

  getStatusLabel(status) {
    let str = this.getStatusJob(status);
    let count = this.countStatusData[status];
    if (count) {
      str += ` (${count})`;
    }
    return str;
  }

  private buildQuery(): any {
    let q = { page: this.page || 1, loaded: Date.now() }
    if (this.search) {
      q['search'] = this.search
    }
    let condition: any = this.getFormData_JSON(true);
    if (condition.status && !Utils.isArrayNotEmpty(condition.status)) {
      delete condition.status;
    }
    if (this.needPOPChecked) {
      condition.isNeedPOD = true;
    }
    if (condition.sort) {
      q['sort'] = condition.sort;
      delete condition.sort;
    }
    const f = JSON.stringify(condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    return q;
  }

  loadData() {
    this.routeWithQueryUrl(this.buildQuery());
    this.countStatus();
    this.getListDispatcherHaveJobAfterFilter();
  }

  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }
  public endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }

  isStatusSelected(status) {
    return !!this.selectedStatus[status];
  }

  onBtnStatus(status) {
    this.selectedStatus[status] = !this.selectedStatus[status];
    let arr = [];
    for (let key of Object.keys(this.selectedStatus)) {
      if (this.selectedStatus[key]) {
        arr.push(key);
      }
    }
    this.setItemValue('status', arr);
    this.onChange(arr, 'status');
  }

  // "Need Carrier" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Carrier: has not assigned yet
  public needCarrierChecked = false;
  onCheckboxNeedCarrier(value) {
    this.selectedStatus = {};
    if (value) {
      this.setItemValue('carrierId', 'no');
      this.onBtnStatus(Const.JobStatus.created);
    } else {
      this.setItemValue('carrierId', null);
      this.setItemValue('status', DispatchListHeaderFilterV3.defaultStatus);
      this.selectedStatus = { created: true, inProgress: true }
      this.loadData();
    }
  }

  // "Need Dispatcher" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Dispatcher: has not assigned yet
  public needDispatcherChecked = false;
  onCheckboxNeedDispatcher(value) {
    this.selectedStatus = {};
    if (value) {
      this.setItemValue('picId', 'no');
      this.onBtnStatus(Const.JobStatus.created);
    } else {
      this.setItemValue('picId', null);
      this.setItemValue('status', DispatchListHeaderFilterV3.defaultStatus);
      this.selectedStatus = { created: true, inProgress: true }
      this.loadData();
    }
  }

  public needPOPChecked = false;
  onCheckboxNeedPOD(value) {
    this.selectedStatus = {};
    if (value) {
      this.setItemValue('carrierId', null);
      this.setItemValue('picId', null);
      this.onBtnStatus(Const.JobStatus.completed);
    } else {
      this.setItemValue('status', DispatchListHeaderFilterV3.defaultStatus);
      this.selectedStatus = { created: true, inProgress: true }
      this.loadData();
    }
  }

  onFocusCustomerFilter() {
    this.expandFilterPanelWhenClickCustomerFilter();
  }

  onChange(value, key) {
    if (key == 'vehicleType') {
      this.setItemValue(key, value.code);
    }
    if (key == 'clientId') {
      this.expandFilterPanelWhenClickCustomerFilter();
    }
    this.page = 1;
    setTimeout(() => {
      this.loadData();
      let statusArr = this.getItemValue('status') ?? [];
      let carrierId = this.getItemValue('carrierId');
      this.needCarrierChecked = carrierId == 'no' && statusArr.includes(Const.JobStatus.created);
      let picId = this.getItemValue('picId');
      this.needDispatcherChecked = picId == 'no' && statusArr.includes(Const.JobStatus.created);
      if (!statusArr.includes(Const.JobStatus.completed)) {
        this.needPOPChecked = false;
      }
    }, 1); // phải kết thúc hàm onChange thì trong form mới có dữ liệu
  }

  private expandFilterPanelWhenClickCustomerFilter() {
    setTimeout(() => {
      if (!this.isExpanded && this.getOffsetTopCustomerFilter == this.getOffsetTopSortFilter) {
        this.offsetCustomerFilter = this.getOffsetTopCustomerFilter;
      }
      if (!this.isExpanded && this.offsetCustomerFilter > 0 && this.getOffsetTopCustomerFilter > this.offsetCustomerFilter) {
        this.toggleExpand();
      }
    }, 100);
  }

  protected beforeBindModel(model: any): any {
    let statusArr = model.status ?? [];
    for (let stt of statusArr) {
      this.selectedStatus[stt] = true;
    }
    this.needCarrierChecked = model.carrierId == 'no' && statusArr.includes(Const.JobStatus.created);
    this.needDispatcherChecked = model.picId == 'no' && statusArr.includes(Const.JobStatus.created);
    this.needPOPChecked = model.isNeedPOD == true && statusArr.includes(Const.JobStatus.completed);
    return model;
  }

  protected setEnableFormGroup(enabled: boolean): void {
    // do nothing
  }

  public isFetchingCarrier = false;
  private fetchListCarriers() {
    let url = `${Const.APIURI_CARRIERS}/list/all_for_dispatch`;
    this.isFetchingCarrier = true;
    this.api.GET(url).subscribe(
      resp => {
        Log.d('fetchListData done ', resp);
        this.listCarriers = resp.data.list_data;
        this.isFetchingCarrier = false;
      }, err => {
        this.showErr(err);
        this.isFetchingCarrier = false;
      }
    );
  }

  public isFetchingDispatcher = false;
  private fetchListDispatchers(silent: boolean = false) {
    this.isFetchingDispatcher = true;
    this.api.getListDispatcherUsersFilter().subscribe(
      resp => {
        this.listDispatchers = resp?.data?.list_data ?? [];
        this.isFetchingDispatcher = false;
        this.getListDispatcherHaveJobAfterFilter();
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingDispatcher = false;
      }
    );
  }

  public isFetchingCarrierSaleRep = false;
  private fetchListCarrierSales(silent: boolean = false) {
    this.isFetchingCarrierSaleRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSales = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingCarrierSaleRep = false;
      }
    );
  }

  public isFetchingClientSaleRep = false;
  private fetchListClientSales(silent: boolean = false) {
    this.isFetchingClientSaleRep = true;
    this.api.getListClientSalesRepUsersFilter().subscribe(
      resp => {
        this.listClientSales = resp?.data?.list_data ?? [];
        this.isFetchingClientSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingClientSaleRep = false;
      }
    );
  }

  onBtnExport() {
    this.isExporting = true;
    let query = this.buildQuery();
    query.download = 1;
    if (!query.filter && !query.search) {
      query.exportAll = 1;
    }
    this.api.postExport(`${Const.APIURI_JOBS}/export`, query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

  private countStatus() {
    let filter = {};
    const paramQuery = this.buildQuery()
    if (paramQuery?.filter) {
      filter = JSON.parse(paramQuery.filter);
    }
    this.api.POST(`${Const.APIURI_JOBS}/count_status`, filter).subscribe(
      resp => {
        this.countStatusData = resp.data;
        this.lastUpdated = resp.data?.lastUpdated;
        this.requestId = resp.data?.requestId;
      }, err => {
        Log.e(err);
      }
    );
  }

  public onDispatcherAssigned(dispacher: any) {
    for (let item of this.listDispatchers) {
      if (item.id == dispacher.id) {
        // Đã có trong danh sách rồi, ko cần xử lý nữa
        return;
      }
    }
    // Chưa có trong danh sách thì thêm vào
    this.listDispatchers.push(dispacher);

    // Bên trên là update ngay để đáp ứng tính real-time, đảm bảo dispatcher mới sẽ có mặt trong danh sách fiter.
    // Sau đó thì âm thầm gọi lại API để lấy danh sách chuẩn, đảm bảo những dispatcher không cần thiết sẽ không nằm trong danh sách filter.
    this.fetchListDispatchers(true);
  }

  public getListDispatcherForDropdown() {
    let list = [...this.listDispatchers];
    return list.map(dispatcher => {
      const item = this.dispatchersHaveJobs.find(i => i._id == dispatcher.id);
      dispatcher.jobCount = item?.jobCount || 0;
      return dispatcher;
    }).filter(dispatcher => dispatcher.jobCount);
  }

  public getListDispatcherHaveJobAfterFilter() {
    this.listDispatcherForDropdown = this.listDispatcherForDropdown.map((dispatcher) => {
      dispatcher.jobCount = 0;
      return dispatcher;
    });
    const query = this.buildQuery();
    this.api.POST(`${Const.APIURI_JOBS}/count_job_for_dispatcher_list`, query).subscribe(
      resp => {
        this.dispatchersHaveJobs = resp.data.list_data;
        setTimeout(() => {
          this.listDispatcherForDropdown = this.getListDispatcherForDropdown();
        }, 100);
      }, err => {
        Log.e(err);
      }
    );
  }

  public getCountJobOfDispatcher(dispatcher) {
    const job = dispatcher?.job ?? [];
    const statuses = [];
    for (let key of Object.keys(this.selectedStatus)) {
      if (this.selectedStatus[key]) {
        statuses.push(key);
        if (key === 'created') statuses.push(undefined);
      }
    }

    const jobByStatus = job.filter(item => statuses.includes(item.status))
    return statuses.length ? jobByStatus.length : job.length;
  }

  public dispatcherName(item) {
    let name: string = this.getFullName(item);
    if (item.jobCount) {
      name = `${name} (${item.jobCount})`;
    }
    return name;
  }

  onBtnReassign() {
    DialogService.openFormDialog1(ReAssignDispatcherComponent, {
      nzComponentParams: {
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.fetchListDispatchers();
          this.onRefresh();
        }
      },
      nzClassName: "re-assign-dispatcher-form",
    });
  }

  getTimezoneShort() {
    const localTimeZone = DateUtil.getLocalTimeZone();
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(localTimeZone);
    return localTimeZoneShort || '';
  }

  get lastUpdatedTime() {
    if (!this.lastUpdated) return 'N/A';
    return DateUtil.format(this.lastUpdated, Const.DATETIME_FORMAT_FROM_DB);
  }

  getLabelHotlist(value: string) {
    switch (value) {
      case 'carrierAssignment': return 'Carrier Assignment';
      case 'etaToPickup': return 'ETA to Pickup';
      case 'pickupSuccessful': return 'Pickup Successful';
      case 'etaToDelivery': return 'ETA to Delivery';
      case 'deliverySuccessful': return 'Delivery Successful';
      case 'mustArriveAtPickupOnTime': return 'Must Arrive at Pickup On Time';
      case 'mustArriveAtDeliveryOnTime': return 'Must Arrive at Delivery On Time';
      default: return value;
    }
  }

  public getCarrierMC(carrier): string {
    return carrier?.basicInfo?.mc ?? '';
  }

  public getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? '';
  }

  public getCarrierNameWithMCandDOT(carrier): string {
    if(carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
    return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`
    return carrier?.basicInfo?.name ?? '';
  }

}