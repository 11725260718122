<div class="dashboard-child-container no-padding list dispatch-container">
  <div class="list-header small">
    <div class="box-container">
      <div list-header-filter-v4 #listHeader></div>
    </div>
  </div>

  <div class="detail-body">
    <div>

      <div scrollTracker [isScrollTop]="isScrollTop" (scrollingFinished)="getDataMore()" #target
        class="navigation-dispatch-list dispatch-dynamic-height" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06); position: relative;">
        <nz-table #nzTable nzBordered="true" [nzData]="listData" nzSize="small" [nzFrontPagination]="false"
          [nzLoading]="isLoading && listData?.length == 0" [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData"
          [nzSize]="'small'">
          <ng-template #tplNoData>
            <div *ngIf="!isLoading && !listData?.length" class="nodata"><i nz-icon nzType="search"
                nzTheme="outline"></i>No data</div>
          </ng-template>
          <ng-template #tplLoading>
            <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
          </ng-template>
          <tbody >
            <tr *ngFor="let item of listData; let i = index">
              <a [routerLink]="[routeAdminDispatchList, item.id]" [queryParams]="queryParams">
                <div list-job-item [job]="item" [pinnedNotes]="notesOfJobs[item.id]" [driverMessages]="driverMessagesOfJobs[item.id]" [isSelectedItem]="selectedItem === item.id"></div>
              </a>
            </tr>
            <ng-container *ngIf="isLoading && listData?.length">
              <tr>
                <td>
                  <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="listData?.length == totalCount && totalCount > 0">
              <tr>
                <td>
                  <div style="display: flex;justify-content: center; margin-top: 10px;">
                    <img class="img-notify-dispatch" src="assets/img/notifi-dispatch.png" width="50px" alt="">
                  </div>

                  <div style="text-align: center; margin-top: 10px;font-size: 13px;font-weight: 300;">
                    Total: {{totalCount}} loads.
                    <br />That's all your loads based on filters.
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </div>
    <div style="flex: 1;">
      <router-outlet (activate)="onRouterActivate($event)" (deactivate)="onRouterDeActivate($event)"></router-outlet>
    </div>
  </div>
</div>
