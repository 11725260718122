import { Shipment } from "@wearewarp/types/data-model";
import { Const } from "@wearewarp/universal-libs";

export default class ShipmentEntity {
  private data: Shipment;

  init(shipment: Shipment) {
    this.data = shipment;
    return this;
  }

  getId() {
    return this.data.id;
  }

  getWarpId() {
    return this.data.warpId
  }

  getLastJobId() {
    return this.data.lastJobId
  }

  getPickInfo() {
    return this.data.deliveryInfos.find(d => d.type == Const.TaskType.PICKUP)
  }

  getDropInfo() {
    return this.data.deliveryInfos.find(d => d.type == Const.TaskType.DROPOFF)
  }

  getClient(){
    return this.data.metadata.client
  }

  getType() {
    return this.data.shipmentType
  }

  toJSON() {
    return {
      ...this.data
    }
  }

  getTags() {
    return this.data.tags
  }
}
