<div class="dispatch-detail">
  <div class="main-content dispatch-dynamic-height">
    <dispatch-route-header></dispatch-route-header>
    <div class="route-tab">
      <nz-tabset [nzSelectedIndex]="tabSelected">
        <nz-tab nzTitle="Route">
          <dispatch-route-detail></dispatch-route-detail>
        </nz-tab>
        <nz-tab nzTitle="Carrier & Driver">
          <ng-template nz-tab>
            <div dispatch-carrier-and-driver></div>
          </ng-template>
        </nz-tab>
<!--        <nz-tab nzTitle="Sales">-->
<!--          <ng-template nz-tab>-->
<!--            <div dispatch-sales></div>-->
<!--          </ng-template>-->
<!--        </nz-tab>-->
        <nz-tab nzTitle="Documents">
          <ng-template nz-tab>
            <div dispatch-documents></div>
          </ng-template>
        </nz-tab>
        <nz-tab nzTitle="Customers">
          <ng-template nz-tab>
            <div dispatch-customer></div>
          </ng-template>
        </nz-tab>
        <nz-tab nzTitle="Revenues" *ngIf="isAccounting">
          <ng-template nz-tab>
            <div dispatch-revenue></div>
          </ng-template>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
  <div class="right-sidebar dispatch-dynamic-height">
    <dispatch-sidebar-map></dispatch-sidebar-map>
    <dispatch-sidebar-menu [jobId]="routeId"></dispatch-sidebar-menu>
  </div>
</div>
