<div *ngIf="isLoading" class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline"></i>
</div>

<div class="edit-sequence" *ngIf="!isLoading">
  <div class="sequence">
    <edit-route-sequence [route]="route"></edit-route-sequence>
  </div>
  <div class="bottom-bar">
    <div class="wrapper">
      <div class="button-controls">
        <button nz-button nzType="default" nzDanger class="cancel-btn" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" class="submit-btn" (click)="onSubmit()" [disabled]="!canSubmit()"
          [nzLoading]="isSubmitting">Save</button>
      </div>
      <div class="info">
        <!-- <div [innerHTML]="statusText"></div> -->
      </div>
    </div>
  </div>

</div>