import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { RouteService } from "../../services/route.service";
import RouteEntity from "../../entities/RouteEntity";
import ShipmentEntity from "../../entities/ShipmentEntity";

@Component({
  selector: "validate-merge-route",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"]
})

export class ValidateMergeRoute extends BaseFormDialog1 {
  @Input() listRoute: RouteEntity[];
  displayInfo: any[];
  isLoading = true;
  route: RouteEntity;
  listRouteForMerge: RouteEntity[] = [];

  constructor(private routeService: RouteService) {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
    this.fetchDataListRoute().then(data => {
      this.displayInfo = this.buildDisplayInfo(data);
      this.listRouteForMerge = data;
      this.isLoading = false;
    });
    this.routeService.routeData$.subscribe(() => {
      this.route = this.routeService.getRoute();
    });
  }

  private fetchDataListRoute() {
    if(!this.listRoute) return;
    const listRoute = this.listRoute.map(async route => {
      const routeService = new RouteService(this.api);
      await routeService.setRouteId(route.getId()).refresh();
      return routeService.getRoute();
    })
    return Promise.all(listRoute);
  }

  private buildDisplayInfo(listRoute: RouteEntity[]) {
    return listRoute.map(route => {
      const firstTask = this.getFirstTask(route);
      const lastTask = this.getLastTask(route);
      return {
        routeId: this.getRouteId(route),
        startAddress: firstTask.getAddress(),
        startDeliveryInfo: firstTask.getInfo(),
        endAddress: lastTask.getAddress(),
        endDeliveryInfo: lastTask.getInfo(),
        tasks: route.getTasks(),
        validateRoute: this.validateRoute(route),
        shipments: route.getShipments(),
      }
    })
  }

  private getFirstTask(route: RouteEntity) {
    if(!route) return;
    return route.getTasks()[0];
  }

  private getLastTask(route: RouteEntity) {
    if(!route) return;
    const tasks = route.getTasks();
    return tasks[tasks.length - 1];
  }

  private getRouteId(route: RouteEntity) {
    if(!route) return;
    return route.getCode();
  }

  private getRouteShipments() {
    return this.route.getShipments();
  }

  private checkRouteIsSubRoute(shipments: ShipmentEntity[], subShipments: ShipmentEntity[]) {
    return subShipments.every(subShipment => shipments.some(shipment => shipment.getWarpId() == subShipment.getWarpId()));
  }

  private validateRoute(route: RouteEntity) {
    if(!route) return;
    const shipments = route.getShipments();
    const shipmentType = route.getShipments()[0].getType();
    if(shipmentType == "FTL") {
      return {
        result: false,
        message: "Shipment type is FTL"
      }
    }
    if(route.getAssignedCarrier()) {
      return {
        result: false,
        message: "Route is assigned carrier"
      }
    }
    if(route.getStatus() != "created") {
      return {
        result: false,
        message: "Route is already"
      }
    }
    if(this.checkRouteIsSubRoute(this.getRouteShipments(), shipments)) {
      return {
        result: false,
        message: "Route has been merged"
      }
    }
    return {
      result: true,
      message: ""
    }
  }

  shouldShowMergeRoute() {
    if(!this.displayInfo) return false;
    return this.displayInfo.filter(item => !item.validateRoute.result).length ? false : true;
  }

  countRouteSelected() {
    return this.listRoute.length;
  }

  countRouteValid() {
    if(!this.displayInfo) return;
    return this.displayInfo.filter(item => item.validateRoute.result).length;
  }

  countRouteInvalid() {
    if(!this.displayInfo) return;
    return this.displayInfo.filter(item => !item.validateRoute.result).length;
  }

  onBtnMergeRoute() {
    this._listRouteForMerge(this.listRouteForMerge);
    this.closeDialog();
  }

  public _listRouteForMerge: (routes: RouteEntity[]) => void = () => {};
}
