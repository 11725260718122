<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">Update Driver Down Time</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div nz-row>
    <div nz-col [nzSpan]="24" class="flex">
      <div class="form-label-v2 single-line fix-height" style="width: 100px;">
        {{getLabel('from')}}
        <span *ngIf="isRequired('from') && getLabel('from').length > 0" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
            <nz-date-picker
              [formControlName]="'from'"
              [nzPlaceHolder]="'Select date time'"
              nzFormat="yyyy-MM-dd HH:mm"
              style="width: 100%;"
              [nzShowTime]="{nzFormat: 'HH:mm'}"
            ></nz-date-picker>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24" class="flex">
      <div class="form-label-v2 single-line fix-height" style="width: 100px;">
        {{getLabel('to')}}
        <span *ngIf="isRequired('to') && getLabel('to').length > 0" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
            <nz-date-picker
              [formControlName]="'to'"
              [nzPlaceHolder]="'Select date time'"
              nzFormat="yyyy-MM-dd HH:mm"
              style="width: 100%;"
              [nzShowTime]="{nzFormat: 'HH:mm'}"
            ></nz-date-picker>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" [canClickCancel]="true"
  nzIconOK="save" labelOK="Save" (onOK)="onBtnSave()"
  (onCancel)="onBtnCancel()"></div>
