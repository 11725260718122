<form class="form-detail" nz-form>
  <div class="flex">
    <div *ngIf="!links" class="f20 bottom20 flex1">Add Carrier Tracking URL</div>
    <div *ngIf="links" class="f20 bottom20 flex1">Edit Carrier Tracking URL</div>
  </div>
  <textarea
    nz-input
    class="top20 add-custom-tracking-content"
    [nzAutosize]="{ minRows: 4, maxRows: 8 }"
    placeholder="Enter your tracking link here..."
    [(ngModel)]="link"
    [ngModelOptions]="{standalone: true}"
  ></textarea>
</form>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="true"
  [canClickOK]="true"
  labelOk="Save"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
