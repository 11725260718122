import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { Const } from "@const/Const";

@Component({
  selector: "add-reference-number",
  templateUrl: "./index.html",
  styleUrls: [
    "../../../../../dialogs/dialogs.scss",
    "../../../../../../styles/row-col.scss",
    "./index.scss",
  ],
})
export class AddReferenceNumber extends BaseFormDialog1 {
  @Input() jobId;
  @Input() referenceNumber:string[];

  onBtnSave(): void {
    this.setEnableFormGroup(false);
    this.startProgress();
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    const referenceNumber = this.referenceNumber
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/carrier_reference_number`, {referenceNumber}).subscribe(
      resp => {
        Log.d('update reference number done ', resp);
        this.onUpdateSuccess(resp);
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }
}
