import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";

@Component({
  selector: "[search-note-dialog]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class SearchNoteDialog extends BaseDialog {
  isSearching: boolean = false;
  searchNoteKeyWord: string = "";
  listConversations: any = [];

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  get totalCount() {
    return this.listConversations.length;
  }

  doSearchByNote(event) {
    if (!event) {
      this.listConversations = [];
      return;
    }
    this.isSearching = true;
    this.searchNoteKeyWord = event;
    const messageType = "note";
    const jobType = "job";
    const apiUrl = `${Const.APIURI_CONVERSATIONS}/note-search?subjectType=${jobType}&type=${messageType}&search=${this.searchNoteKeyWord}`;
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        this.listConversations = resp?.data?.list_data || [];
        this.isSearching = false;
      },
      (err) => {
        this.isSearching = false;
        this.showErr(err);
      }
    )
  }
}
