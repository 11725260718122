<div class="dispatch-detail">
  <div class="main-content dispatch-dynamic-height">
    <div style="width: 100%;height: 100%; display: flex; justify-content: center;align-items: center;">
      <nz-empty
        [nzNotFoundContent]="contentTpl"
      >
        <ng-template #contentTpl>
          <div style="opacity: 70%">No results found. Please adjust your filter or select 'All Loads'.</div>
        </ng-template>
      </nz-empty>
    </div>
  </div>
</div>
