import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { Const } from "@const/Const";
import { toArray } from "underscore";

@Component({
  selector: "add-trailer-number",
  templateUrl: "./add-trailer-number.html",
  styleUrls: [
    "../../../../../dialogs/dialogs.scss",
    "../../../../../../styles/row-col.scss",
    "./add-trailer-number.css",
  ],
})
export class AddTrailerNumber extends BaseFormDialog1 {
  @Input() jobId;
  @Input() trailerNumber: string;
  @Input() truckNumber: string;

  trailerNumberChanged: boolean = false;
  truckNumberChanged: boolean = false;
  oldTrailerNumber: string;
  oldTruckNumber: string;
  ngOnInit() {
    this.oldTrailerNumber = this.trailerNumber;
    this.oldTruckNumber = this.truckNumber;
  }
  onTrailerNumberChange(): void {
    if(this.oldTrailerNumber!== this.trailerNumber) {
      this.trailerNumberChanged = true;
    }
    else {
      this.trailerNumberChanged = false;
    }
  }

  onTruckNumberChange(): void {
    if(this.oldTruckNumber !== this.truckNumber) {
      this.truckNumberChanged = true;
    }
    else {
      this.truckNumberChanged = false;
    }
  }

  isDisabledSaveBtn(): boolean {
    return !this.trailerNumberChanged && !this.truckNumberChanged;
  }

  onBtnSave(): void {
    this.setEnableFormGroup(false);
    this.startProgress();
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    const updatePromises = []; // Array to hold promises for API calls
    if (this.trailerNumberChanged) {
      updatePromises.push(this.api.PUT(`${Const.APIURI_JOBS}/${this.jobId}/trailer_number`, { trailerNumber: this.trailerNumber }).toPromise());
    }
    if (this.truckNumberChanged) {
      updatePromises.push(this.api.PUT(`${Const.APIURI_JOBS}/${this.jobId}/truck_number`, { truckNumber: this.truckNumber }).toPromise());
    }
    Promise.all(updatePromises)
    .then(responses => {
      if(this.trailerNumberChanged && this.truckNumberChanged) {
          Log.d('update trailer number and truck number done', responses);
          Log.d('update trailer number done', responses);
          this.onUpdateSuccess(responses);
        }else{
        responses.forEach(response => {
            if (response.data.trailerNumber != null) { // Check for trailer number update
              Log.d('update trailer number done', response);
              this.onUpdateSuccess(response);
            } else if (response.data.truckNumber != null) { // Check for truck number update
              Log.d('update truck number done', response);
              this.onUpdateSuccess(response);
            }
          });
        }
        this.stopProgress();
      })
      .catch(err => {
        this.showErr(err);
        this.stopProgress();
      });
  }
}
