
<div class="float-right">
  <div (click)="closeDialog()">
    <i class="f16" nz-icon nzType="close"></i>
  </div>
</div>
<div class="content">
  <div class="modal-title font-semibold f15 center-children">
    Merge Routes
  </div>
  <nz-skeleton [nzActive]="true" [nzLoading]="isLoading">
    <div *ngIf="!shouldShowMergeRoute()" class="top15 center-children">
      There are some data you have selected that are not valid for action.
    </div>
    <div class="modal-content top15">
      <nz-table [nzData]="displayInfo" #tableRouteValidate [nzShowPagination]="false">
        <thead>
          <tr>
            <th nzWidth="120px">
              Route ID
            </th>
            <th nzWidth="200px">
              Shipment IDs
            </th>
            <th>
              First Stop Address & Time
            </th>
            <th>
              Last Stop Address & Time
            </th>
            <th nzWidth="80px">
              Validation
            </th>
            <th nzWidth="250px">
              Reason for failure
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let route of tableRouteValidate.data">
            <td>
              {{route?.routeId}}
            </td>
            <td>
              <display-shipment-warpid [tasks]="route.tasks"></display-shipment-warpid>
            </td>
            <td>
              <b>{{route?.startDeliveryInfo?.locationName || "N/A"}}</b>
              <div>{{getAddressText(route?.startAddress) || "N/A"}}</div>
              <div class="shipment-mode">{{getDeliveryInfoTime(route?.startDeliveryInfo) || "N/A"}}</div>
            </td>
            <td>
              <b>{{route?.endDeliveryInfo?.locationName || "N/A"}}</b>
              <div>{{getAddressText(route?.endAddress) || "N/A"}}</div>
              <div class="shipment-mode">{{getDeliveryInfoTime(route?.endDeliveryInfo) || "N/A"}}</div>
            </td>
            <td>
              <div class="center-children f20">
                <span *ngIf="route?.validateRoute?.result" nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
                <span *ngIf="!route?.validateRoute?.result" nz-icon [nzType]="'close-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'red'"></span>
              </div>
            </td>
            <td style="color: red;">
              {{route?.validateRoute?.message}}
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="top15 flex" style="justify-content: flex-end;"> 
      <div class="flex">Total: {{ countRouteSelected() }} route selected
        (
        <div *ngIf="countRouteValid() > 0">Valid: {{countRouteValid()}}</div>
        <div *ngIf="countRouteValid() > 0 && countRouteInvalid() > 0">,</div>
        <div *ngIf="countRouteInvalid() > 0"> Invalid: {{countRouteInvalid()}}</div>
        )
      </div>
    </div>
  </nz-skeleton>
  <div class="top15 flex" style="justify-content: flex-end;">
      <button (click)="onBtnMergeRoute()" [disabled]="!shouldShowMergeRoute()" nz-button nzType="primary" >Merge</button>
  </div>
</div>
