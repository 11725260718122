import { Component, Input, OnInit } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import RouteEntity from "../../entity/RouteEntity";
import { DispatchService } from "../../dispatchService";
import { DateUtil } from "@services/date-utils";
import {InternalMessageService} from '@services/internal-message.service';

@Component({
  selector: "driver-message",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DriverMessage extends BaseFormItem implements OnInit {
  private _jobId: string;

  @Input() get jobId() {
    return this._jobId
  };

  set jobId(value) {
    this._jobId = value;
    if (value) {
      setTimeout(() => {
        this.fetchData();
      }, 100);
    }
  }

  newMsg: string;
  type: 'all' | 'email' | 'sms' = 'sms';
  items: any = {};
  protected route: RouteEntity;
  public driver: any = {};
  isLoading: boolean = true;
  private timerReloadData;
  constructor(private dispatchService: DispatchService,private messageService: InternalMessageService) {
    super();
  }

  ngOnInit(): void {
    this.fetchData();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        this.getDriverInfo();
      })
    )
    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  public startIntervalDriverMessage() {
    if (!this.timerReloadData) {
      this.timerReloadData = setInterval(() => this.fetchDataWithOutLoading(), 30000);
    }
  }

  public stopIntervalDriverMessage() {
    if (this.timerReloadData) {
      clearInterval(this.timerReloadData);
      this.timerReloadData = null;
    }
  }

  private getDriverInfo() {
    this.driver = this.route?.getDriver() || {};
    return true;
  }

  ngOnDestroy() {
    this.stopIntervalDriverMessage();
    this.subscription.unsubscribe();
  }

  formatData(data) {
    let items = {};
    for (let item of data) {
      let date = item.insert?.when;
      item['time'] = DateUtil.dateToString(date, "hh:mm A")

      //group item by day
      if (DateUtil.isToday(date)) date = "Today";
      else if (DateUtil.isYesterday(date)) date = "Yesterday";
      else date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);


      if (!items[date]) items[date] = [];
      items[date].push(item);
    }
    return items;
  }

  fetchDataWithOutLoading() {
    this.startProgress();
    // this.items = {};
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=driver_message`).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        this.items = this.formatData(listData);
        this.stopProgress();
        this.messageService.sendMessage({key:"driver_message_change", subjectId:this.jobId, data:listData});
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  fetchData() {
    this.startProgress();
    this.isLoading = true;
    this.items = {};
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=driver_message`).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        this.items = this.formatData(listData);
        this.stopProgress();
        this.isLoading = false;
        this.messageService.sendMessage({key:"driver_message_change", subjectId:this.jobId, data:listData});
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
      }
    );
  }

  onSubmit = () => {
    if (!this.newMsg) return;
    if (!this.type) return;
    let types = [this.type];
    if(this.type === "all") types = ['email', 'sms'];
    //call API for add new note
    this.startProgress();
    this.isLoading = true;
    let formData = new FormData();
    const jsonData = {
      content: this.newMsg,
      sendTypes: ['sms'],
      driverId: this.driver.id
    }
    formData.append("params", JSON.stringify(jsonData));
    this.api
      .postFormData(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=driver_message`, formData)
      .subscribe(
        (resp) => {
          this.showSuccess("Your Message has been sent successfully.");
          this.newMsg = "";
          this.stopProgress();
          this.isLoading = false;
          this.fetchData();
        },
        (err) => {
          this.showErr(err);
          this.newMsg = "";
          this.stopProgress();
          this.isLoading = false;
        }
      );
  };

  getDateItems() {
    return Object.keys(this.items);
  }

  get shouldShowBtnSubmit() {
    if (this.isLoading) return false;
    if (!this.newMsg) return false;
    return true;
  }
}
